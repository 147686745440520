import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch, connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import * as i18n from "app/store/ducks/i18n.duck";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { RootState } from "../../store/store";

interface Language {
  lang: string;
  name: string;
  flag: string;
}

const languages: Language[] = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/260-united-kingdom.svg"),
  },
  {
    lang: "de",
    name: "German",
    flag: toAbsoluteUrl("/media/flags/162-germany.svg"),
  },
  {
    lang: "da",
    name: "Danish",
    flag: toAbsoluteUrl("/media/flags/174-denmark.svg"),
  },
];

interface LanguageSelectorProps {
  iconType?: string;
  setLanguage: (lang: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  iconType,
  setLanguage,
}) => {
  const lang = useSelector((state: RootState) => state.i18n.lang);
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const currentLanguage = system_languages
    ? system_languages.find((x) => x.short_code === lang)
    : {
        short_code: "en",
        title: {
          en: "English",
        },
        flag: toAbsoluteUrl("/media/flags/260-united-kingdom.svg"),
      };

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <span
          className={clsx("kt-header__topbar-icon", {
            "kt-header__topbar-icon--brand": iconType === "brand",
          })}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={toAbsoluteUrl(`/media/flags/${currentLanguage?.flag}`)}
            alt={currentLanguage?.title.en}
          />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
          {system_languages &&
            system_languages.map((language) => (
              <li
                key={language.short_code}
                className={clsx("kt-nav__item", {
                  "kt-nav__item--active":
                    language.short_code === currentLanguage?.short_code,
                })}
              >
                <span
                  onClick={() => setLanguage(language.short_code)}
                  className={clsx("kt-nav__link", {
                    "kt-nav__link--active":
                      language.short_code === currentLanguage?.short_code,
                  })}
                >
                  <span className="kt-nav__link-icon">
                    <img
                      src={toAbsoluteUrl(`/media/flags/${language.flag}`)}
                      alt={language.title.en}
                    />
                  </span>
                  <span className="kt-nav__link-text">{language.title.en}</span>
                </span>
              </li>
            ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapDispatchToProps = {
  setLanguage: i18n.actions.setLanguage,
};

export default connect(null, mapDispatchToProps)(LanguageSelector);
