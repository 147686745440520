import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { statisticService } from "../services";
import Snackbar from "./Snackbar";
import { ITodoEventTotal } from "../services/statistic.service";
import NoData from "../partials/NoData";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";

interface TabPanelProps {
  children: any;
  value: any;
  index: any;
  other?: any;
}

const TabPanel = (props: TabPanelProps) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`scrollable-auto-tabpanel-${props.index}`}
      aria-labelledby={`scrollable-auto-tab-${props.index}`}
      {...props.other}
    >
      <Box p={"1 1"}>{props.children}</Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const options = ["all", "month", "week"];

const ITEM_HEIGHT = 48;

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; bgColor: string }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            height: "8px",
            borderRadius: 12,
            backgroundColor: "#E1E6FB",
            "& .MuiLinearProgress-bar1Determinate": {
              backgroundColor: props.bgColor,
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 13, fontWeight: 700 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const Todo = () => {
  const intl = useIntl();
  const [value, setValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>(
    intl.formatMessage({
      id: "STANDARD.ALL",
    })
  );
  const [todos, setTodos] = useState<ITodoEventTotal>();
  const [events, setEvents] = useState<ITodoEventTotal>();
  const [tab, setTab] = useState<Boolean>(false);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!tab)
      statisticService
        .getTodosTotal([
          {
            name: "filter",
            value: filter,
          },
        ])
        .then((data) => {
          if ("error" in data) {
            // @ts-ignore
            if ("code" in data.error) {
              throw new Error(data.error.message);
            } else {
              throw new Error(data.error);
            }
          }

          setTodos(data);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
  }, [tab, filter]);

  useEffect(() => {
    if (tab)
      statisticService
        .getEventsTotal([
          {
            name: "filter",
            value: filter,
          },
        ])
        .then((data) => {
          if ("error" in data) {
            // @ts-ignore
            if ("code" in data.error) {
              throw new Error(data.error.message);
            } else {
              throw new Error(data.error);
            }
          }

          setEvents(data);
        })
        .catch((e) => {
          Snackbar.error(e.message);
        });
  }, [tab, filter]);

  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };

  function handleChange(event: any, newValue: number) {
    setValue(newValue);
  }

  const getOthers = (
    array: Array<{
      category: string;
      count: number;
      title: { [key: string]: string };
      color: string;
    }>
  ) => {
    let arr = array.slice(3, array.length - 1)?.map((x) => x.count);

    return (
      arr?.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      ) || 0
    );
  };

  return (
    <Card sx={{ px: 2, py: 0, height: "349px" }}>
      <CardContent sx={{ padding: 0 }}>
        <Grid container spacing={1}>
          <Grid item sm={8} xs={8} md={4} lg={4} xl={4}>
            <Box sx={{ padding: 0, mb: 2 }}>
              <Tabs value={value} onChange={handleChange} aria-label="Todos">
                <Tab
                  sx={{
                    pb: 1,
                    pt: 2,
                    pl: 0,
                    pr: 0,
                    minWidth: "66px",
                    fontSize: 16,
                    textTransform: "capitalize",
                  }}
                  label={intl.formatMessage({
                    id: "STANDARD.TODO",
                    defaultMessage: "Todo",
                  })}
                  onClick={() => {
                    setTab(false);
                    setFilter("all");
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    pb: 1,
                    pt: 2,
                    pl: 0,
                    pr: 0,
                    minWidth: "66px",
                    fontSize: 16,
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setTab(true);
                    setFilter("all");
                  }}
                  label={intl.formatMessage({
                    id: "STANDARD.EVENT",
                    defaultMessage: "Event",
                  })}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid
            item
            sm={4}
            xs={4}
            md={8}
            lg={8}
            xl={8}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <IconButton
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              edge="start"
              color="inherit"
              disableFocusRipple={true}
              sx={{
                borderRadius: 0,
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-label="close"
            >
              <Typography
                variant={"inherit"}
                sx={{ fontSize: "13px", textTransform: "capitalize" }}
              >
                {filter}
              </Typography>
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(filter)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  sx={{
                    textTransform: "capitalize",
                  }}
                  key={option}
                  selected={option === filter}
                  onClick={() => {
                    handleClose(option);
                  }}
                >
                  {intl.formatMessage({
                    id: "STANDARD." + option.toUpperCase(),
                  })}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0}>
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Typography
                variant={"h5"}
                sx={{ fontSize: 16, fontWeight: 500, color: "#01B055", mb: 2 }}
              >
                {intl.formatMessage({ id: "CHART.ACTIVE" })}
              </Typography>
              {todos ? (
                <>
                  {todos.data.notexpired.length > 0 ? (
                    <>
                      {todos.data.notexpired.slice(0, 3).map((data) => (
                        <Box
                          sx={{ width: "100%", mb: 2, cursor: "pointer" }}
                          onClick={() => {
                            history.push(
                              `todos?category=${data.category}&todos=1&not_expired=1`
                            );
                          }}
                        >
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {data.title[intl.locale]}
                          </Typography>
                          <LinearProgressWithLabel
                            value={(data.count * 100) / todos?.total.notexpired}
                            bgColor={data.color}
                          />
                        </Box>
                      ))}

                      {todos.data.notexpired.length > 3 && (
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {intl.formatMessage({ id: "CHART.OTHERS" })}
                          </Typography>
                          <LinearProgressWithLabel
                            value={
                              todos
                                ? (getOthers(todos.data.notexpired) * 100) /
                                  todos?.total.notexpired
                                : 0
                            }
                            bgColor={"#45C3ED"}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <NoData height={240} />
                  )}
                </>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 195,
                  }}
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Typography
                variant={"h5"}
                sx={{ fontSize: 16, fontWeight: 500, color: "#EC3033", mb: 2 }}
              >
                {intl.formatMessage({ id: "CHART.EXPIRED" })}
              </Typography>
              {todos ? (
                <>
                  {todos.data.expired.length > 0 ? (
                    <>
                      {todos.data.expired.slice(0, 3).map((data) => (
                        <Box
                          sx={{ width: "100%", mb: 2, cursor: "pointer" }}
                          onClick={() => {
                            history.push(
                              `todos?category=${data.category}&todos=1&expired=1`
                            );
                          }}
                        >
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {data.title[intl.locale]}
                          </Typography>

                          <LinearProgressWithLabel
                            value={(data.count * 100) / todos?.total.expired}
                            bgColor={data.color}
                          />
                        </Box>
                      ))}

                      {todos.data.expired.length > 3 && (
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {intl.formatMessage({ id: "CHART.OTHERS" })}
                          </Typography>
                          <LinearProgressWithLabel
                            value={
                              todos
                                ? (getOthers(todos.data.expired) * 100) /
                                  todos?.total.expired
                                : 0
                            }
                            bgColor={"#45C3ED"}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <NoData height={240} />
                  )}
                </>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 195,
                  }}
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Typography
                variant={"h5"}
                sx={{ fontSize: 16, fontWeight: 500, color: "#01B055", mb: 2 }}
              >
                {intl.formatMessage({ id: "CHART.ACTIVE" })}
              </Typography>
              {events ? (
                <>
                  {events.data.notexpired.length > 0 ? (
                    <>
                      {events.data.notexpired.slice(0, 3).map((data) => (
                        <Box
                          sx={{ width: "100%", mb: 2, cursor: "pointer" }}
                          onClick={() => {
                            history.push(
                              `todos?category=${data.category}&events=1&not_expired=1`
                            );
                          }}
                        >
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {data.title[intl.locale]}
                          </Typography>
                          <LinearProgressWithLabel
                            value={
                              (data.count * 100) / events?.total.notexpired
                            }
                            bgColor={data.color}
                          />
                        </Box>
                      ))}

                      {events.data.notexpired.length > 3 && (
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {intl.formatMessage({ id: "CHART.OTHERS" })}
                          </Typography>
                          <LinearProgressWithLabel
                            value={
                              events
                                ? (getOthers(events.data.notexpired) * 100) /
                                  events?.total.notexpired
                                : 0
                            }
                            bgColor={"#45C3ED"}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <NoData height={240} />
                  )}
                </>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 195,
                  }}
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              <Typography
                variant={"h5"}
                sx={{ fontSize: 16, fontWeight: 500, color: "#EC3033", mb: 2 }}
              >
                {intl.formatMessage({ id: "CHART.EXPIRED" })}
              </Typography>
              {events ? (
                <>
                  {events.data.expired.length > 0 ? (
                    <>
                      {events.data.expired.slice(0, 3).map((data) => (
                        <Box
                          sx={{ width: "100%", mb: 2, cursor: "pointer" }}
                          onClick={() => {
                            history.push(
                              `todos?category=${data.category}&events=1&expired=1`
                            );
                          }}
                        >
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {data.title[intl.locale]}
                          </Typography>
                          <LinearProgressWithLabel
                            value={(data.count * 100) / events?.total.expired}
                            bgColor={data.color}
                          />
                        </Box>
                      ))}

                      {events.data.expired.length > 3 && (
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant={"h5"}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#2A3042",
                              mb: 1,
                            }}
                          >
                            {intl.formatMessage({ id: "CHART.OTHERS" })}
                          </Typography>
                          <LinearProgressWithLabel
                            value={
                              events
                                ? (getOthers(events.data.expired) * 100) /
                                  events?.total.expired
                                : 0
                            }
                            bgColor={"#45C3ED"}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <NoData height={240} />
                  )}
                </>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 195,
                  }}
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      </CardContent>
    </Card>
  );
};

export default Todo;
