import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { getUserByToken } from "app/crud/auth.crud";
import * as routerHelpers from "app/router/RouterHelpers";
import { IAction, IUser } from "app/interfaces";
import { rootReducer } from "app/store/rootDuck";
import { IBusiness } from "app/services/business.service";

export interface IAuthState {
  user: IUser | undefined;
  authToken: string | undefined;
  platform: string | undefined;
  business: IBusiness | undefined;
  refresh: boolean;
  switchUsers: IUser[] | undefined;
}

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UpdateBusiness: "[Update Business] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  Refresh: "[Refresh] Auth API",
  SetFingerprint: "[Fingerprint] Action",
  SwitchToUser: "[Switch to user] Action",
  AddToSwitchUsers: "[Add User to Switch Users] Action",
  UpdateSwitchUsers: "[Update Switch Users] Action",
  RemoveFromSwitchUsers: "[Remove User to Switch Users] Action",
};

const initialAuthState: IAuthState = {
  user: undefined,
  authToken: undefined,
  platform: undefined,
  business: undefined,
  refresh: false, // fetching from database
  switchUsers: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "auth",
    whitelist: ["user", "authToken", "business", "platform", "switchUsers"],
  },
  (state: IAuthState = initialAuthState, action: IAction) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, business } = action.payload;
        return {
          authToken,
          user: undefined,
          refresh: false,
          platform: undefined,
          business,
          switchUsers: [],
        };
      }
      case actionTypes.SwitchToUser: {
        const { authToken, business } = action.payload;
        return {
          ...state,
          authToken,
          business,
        };
      }
      case actionTypes.UpdateBusiness: {
        const { business } = action.payload;
        // localStorage.clear();
        return { ...state, business };
      }

      case actionTypes.AddToSwitchUsers: {
        const { user } = action.payload;
        let combined: IUser[] | undefined = state.switchUsers;

        const checkUser = state.switchUsers?.find(
          (switchUser) => switchUser._id === user._id
        );

        if (combined && !checkUser) combined = [...combined, ...[user]];

        return { ...state, switchUsers: combined };
      }

      case actionTypes.UpdateSwitchUsers: {
        const { user } = action.payload;
        const stateUsers = state.switchUsers;
        const checkUser = state.switchUsers?.find(
          (switchUser) => switchUser._id === user._id
        );
        let index = undefined;
        if (checkUser) index = stateUsers?.indexOf(checkUser);

        if (index && stateUsers) {
          stateUsers[index] = user;
        }

        return { ...state, switchUsers: stateUsers };
      }

      case actionTypes.RemoveFromSwitchUsers: {
        const { user_id } = action.payload;
        const filteredPeople = state.switchUsers?.filter(
          (item) => item._id !== user_id
        );
        return { ...state, switchUsers: filteredPeople };
      }

      case actionTypes.Refresh: {
        const { authToken } = action.payload;
        return { ...state, authToken, user: undefined, refresh: false };
      }

      case actionTypes.UserRequested: {
        return { ...state, refresh: true };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { ...state, authToken, user: undefined, refresh: false };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        rootReducer(undefined, { payload: null, type: "" });
        localStorage.clear();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user, refresh: false };
      }

      case actionTypes.SetFingerprint: {
        const { platform } = action.payload;
        return { ...state, platform, refresh: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken: string, business: IBusiness) => ({
    type: actionTypes.Login,
    payload: { authToken, business },
  }),
  switchToUser: (authToken: string, business: IBusiness) => ({
    type: actionTypes.SwitchToUser,
    payload: { authToken, business },
  }),
  updateBusiness: (business: IBusiness) => ({
    type: actionTypes.UpdateBusiness,
    payload: { business },
  }),
  addToSwitchUsers: (user: IUser) => ({
    type: actionTypes.AddToSwitchUsers,
    payload: { user },
  }),
  updateSwitchUsers: (user: IUser) => ({
    type: actionTypes.UpdateSwitchUsers,
    payload: { user },
  }),
  removeFromSwitchUsers: (userId: string) => ({
    type: actionTypes.RemoveFromSwitchUsers,
    payload: { userId },
  }),
  register: (authToken: string) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  fingerprint: (device: string) => ({
    type: actionTypes.SetFingerprint,
    payload: { platform: device },
  }),
  fulfillUser: (user: IUser) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Refresh, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.SwitchToUser, function* switchToUserSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { authToken, business } = yield select((state) => state.auth);
    const { data: user } = yield getUserByToken(authToken, business._id);
    yield put(actions.fulfillUser(user));
    yield put(actions.addToSwitchUsers(user));
  });
}
