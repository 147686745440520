import React, { useEffect, useRef, useState } from "react";
import { IColumns } from "app/partials/layout/DataTable";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { accountingService } from "app/services";
import { useTable } from "app/hooks/useTable";
import { GridTable } from "app/partials/layout/GridTable";
import { useIntl } from "react-intl";
import { hasPermission, PermissionsGate } from "app/permission/PermissionsGate";
import EditIcon from "app/partials/icons/EditIcon";
import ConfirmDialog from "app/partials/dialogues/confirmDialogue";
import DeleteIcon from "app/partials/icons/DeleteIcon";
import { usePermissions } from "app/hooks/useRole";
import moment from "moment";
import ExpenseStore from "./store";
import { IExpense } from "app/services/accounting.service";
import ExpenseShow from "./show";
import QuickCards from "./quickCards";
import ExpenseInfo from "./info";
import ExpenseFilter from "./filter";
import { useHistory } from "react-router-dom";

const Expenses = () => {
  const intl = useIntl();
  const permissions = usePermissions();
  const history = useHistory();
  const { data, xhrLoading, updateQuery, refresh, remove, queries, setQuery } =
    useTable<IExpense>({
      fetch: accountingService.getAllExpenses,
      delete: accountingService.deleteExpense,
      query: [
        {
          name: "per_page",
          value: 5,
        },
      ],
    });

  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);
  const [refreshCard, setRefreshCards] = useState<boolean>(false);
  const [expense, setExpense] = useState<string | null>(null);
  const [expenseInfo, setExpenseInfo] = useState<string | null>(null);
  const [confirmId, setConfirmId] = useState<string | null>(null);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.BILLING_ID",
        defaultMessage: "Billing id",
      }),
      field: "billing_id",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.billing_id}>
            <Typography variant="inherit">{params.row?.billing_id}</Typography>
          </Tooltip>
        );
      },
      minWidth: 50,
    },
    {
      headerName: intl.formatMessage({
        id: "LABEL.CASENUMBER",
        defaultMessage: "Case number",
      }),
      field: "case",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.case?.key}>
            <Typography variant="inherit">{params.row?.case?.key}</Typography>
          </Tooltip>
        );
      },
      minWidth: 40,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.TITLE",
        defaultMessage: "Title",
      }),
      field: "title",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.title}>
            <Typography variant="inherit">{params.row?.title}</Typography>
          </Tooltip>
        );
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.TOTAL",
        defaultMessage: "Total",
      }),
      field: "total",
      renderCell: (params) => {
        return params.row?.total + " " + params.row?.currency;
      },
      minWidth: 130,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.SUPPLIER",
        defaultMessage: "Supplier",
      }),
      field: "supplier",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.supplier?.title ?? "---"}>
            <Typography variant="inherit">
              {params.row?.supplier?.title ?? "---"}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 130,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.INVOICE_DATE",
        defaultMessage: "Invoice date",
      }),
      field: "expense_date",
      renderCell: (params) => {
        return params.row?.invoice_date
          ? moment.unix(params.row?.invoice_date).local().format("DD/MM/YYYY")
          : "---";
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.PAYMENT_DATE",
        defaultMessage: "Payment date",
      }),
      field: "payment_date",
      renderCell: (params) => {
        return params.row?.payment_date
          ? moment.unix(params.row?.payment_date).local().format("DD/MM/YYYY")
          : "---";
      },
      minWidth: 80,
    },
    {
      headerName: intl.formatMessage({
        id: "STANDARD.CREATED_AT",
        defaultMessage: "Created at",
      }),
      field: "created_at",
      renderCell: (params) => {
        return params.row?._created_at
          ? moment.unix(params.row?._created_at).local().format("DD/MM/YYYY")
          : "---";
      },
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({ id: "STANDARD.ACTIONS" }),
      field: "actions",
      renderCell: (params) => {
        return (
          <>
            {params.row?.from === "case" &&
              (!params.row?.expense_category || !params.row?.supplier) && (
                <>
                  <PermissionsGate section={"accounting"} scope={"read"}>
                    <IconButton onClick={() => {}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        width="20"
                        height="20"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill={"rgb(255, 64, 154)"}
                          d="m20,10v7c0,1.103-.897,2-2,2h-7c-1.103,0-2-.897-2-2v-8c0-.552.447-1,1-1s1,.448,1,1v8h3v-3c0-.552.447-1,1-1s1,.448,1,1v3h2v-7h-4c-.553,0-1-.448-1-1s.447-1,1-1h4c1.103,0,2,.897,2,2Zm4-1v10c0,2.761-2.239,5-5,5H3.643c-2.012,0-3.643-1.631-3.643-3.643V3.5C0,1.567,1.567,0,3.5,0h.5c1.657,0,3,1.343,3,3v1h12c2.761,0,5,2.239,5,5ZM2,17.338c.464-.22.977-.338,1.5-.338h.5c.552,0,1-.449,1-1V3c0-.551-.448-1-1-1h-.5c-.827,0-1.5.673-1.5,1.5v13.838Zm20-8.338c0-1.657-1.343-3-3-3H7v10c0,1.657-1.343,3-3,3h-.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5h15.5c1.657,0,3-1.343,3-3v-10Z"
                        />
                      </svg>
                    </IconButton>
                  </PermissionsGate>
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/cases/${params.row?.case?._id}?section=finance&id=${params.row?.transaction}`
                      )
                    }
                  >
                    <i
                      style={{ display: "flex", color: "#8360D2" }}
                      className="fi fi-sr-suitcase-alt"
                    />
                  </IconButton>
                </>
              )}
            <PermissionsGate section={"accounting"} scope={"read"}>
              <IconButton onClick={() => info(params.row?._id)}>
                <i
                  style={{ display: "flex", color: "#FBB73C" }}
                  className="fi fi-sr-eye"
                />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"accounting"} scope={"read"}>
              <IconButton onClick={() => show(params.row?._id)}>
                <EditIcon />
              </IconButton>
            </PermissionsGate>
            <PermissionsGate section={"accounting"} scope={"delete"}>
              <IconButton onClick={() => handleClick(params.row?._id)}>
                <DeleteIcon />
              </IconButton>
            </PermissionsGate>
          </>
        );
      },
      type: "actions",
      align: "right",
      flex: 1,
      headerAlign: "right",
      minWidth: 100,
      sortable: false,
    },
  ];

  const handleClick = (_id: string) => {
    setConfirmId(_id);
  };

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  const show = (id: string) => {
    setExpense(id);
  };

  const info = (id: string) => {
    setExpenseInfo(id);
  };

  return (
    <>
      {confirmId ? (
        <ConfirmDialog
          title="Delete expense?"
          open={Boolean(confirmId)}
          setOpen={() => setConfirmId(null)}
          onConfirm={() => {
            remove(confirmId);
          }}
        >
          Are you sure you want to delete this expense?
        </ConfirmDialog>
      ) : (
        <></>
      )}
      <PermissionsGate section={"accounting"} scope={"read"}>
        <>
          {expense ? (
            <ExpenseShow
              _id={expense}
              open={Boolean(expense)}
              onDone={() => {
                refresh();
                setRefreshCards(true);
              }}
              setDialogue={() => setExpense(null)}
            />
          ) : (
            <></>
          )}
          {expenseInfo ? (
            <ExpenseInfo
              _id={expenseInfo}
              open={Boolean(expenseInfo)}
              setDialogue={() => setExpenseInfo(null)}
            />
          ) : (
            <></>
          )}
        </>
      </PermissionsGate>
      <PermissionsGate section={"accounting"} scope={"read"}>
        <GridTable
          loading={xhrLoading}
          skeleton={!init}
          pageSize={data?.per_page}
          count={data?.total}
          columns={columns}
          rows={data?.data ?? []}
          tableRef={tableRef}
          filterModel={undefined}
          search={(queries) => updateQuery(queries)}
          query={(queries) => updateQuery(queries)}
          filterToolbar={{
            actionPanel:
              permissions && hasPermission(permissions, "accounting", "create")
                ? (props: { open: boolean; close: () => void }) => {
                    return (
                      <ExpenseStore
                        open={props.open}
                        setDialogue={props.close}
                        onDone={() => {
                          refresh();
                          setRefreshCards(true);
                        }}
                      />
                    );
                  }
                : undefined,
            customQuickCards: (
              <QuickCards
                refreshCards={refreshCard}
                queries={queries}
                setQuery={setQuery}
              />
            ),
            filters: (props: { show: boolean }) => {
              return (
                <ExpenseFilter
                  show={props.show}
                  updateQuery={updateQuery}
                  queries={queries}
                  setQuery={setQuery}
                />
              );
            },
          }}
        />
      </PermissionsGate>
    </>
  );
};

export default Expenses;
