import {
  IUrlQuery,
  IRestApiError,
  IRestApiResource,
  IRestApiCollection,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";

export type AvailableLangs = "de" | "da" | "en" | "ar";

export interface ITranslateStore {
  _id: string;
  key: string;
  translations: {
    [key in string]: string;
  };
}
export interface ITranslate {
  _id: string;
  key: string;
  translations: {
    [key in string]: string;
  };

  _created_at?: string;
  _updated_at?: string;
}

const INDEX = API_ROUTES.translations;
const UPDATE = API_ROUTES.translation;
const TRANSLATE_WITH_AI = API_ROUTES.translate_with_ai;

export interface ITranslateService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITranslate> | IRestApiError>;
  updateOne(
    _id: string,
    data: ITranslate
  ): Promise<IRestApiResource<ITranslate> | IRestApiError>;
  translateWithAI(
    data: any
  ): Promise<IRestApiCollection<ITranslate> | IRestApiError>;
}

const translationService: ITranslateService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },
  async updateOne(_id: string, data: ITranslate) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },
  async translateWithAI(data: any) {
    let formData = generateFormData(new FormData(), data);

    return http(`${getRoute(TRANSLATE_WITH_AI)}`, "post", formData);
  },
};

export { translationService };
