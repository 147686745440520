import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IProject } from "./project.service";

export interface ITask {
  _id: string;
  project: IProject;
  labels: string[];
  checklist: any[];
  title: string;
  column: string;
  description: string;
  start: string;
  end: string;
  priority: number;
  position: number;
  status: { value: string; label: string };
  users: IUser[];
  _created_at: string;
  _updated_at: string;
}

export interface ITaskStore {
  title: string;
  project_id: string;
  start: string;
  end: string;
  priority: number;
  status: string;
  users: string[];
  labels: string[];
}

export interface ITaskQuickCardsCount {
  data: {
    completed: number;
    in_progress: number;
    not_started: number;
    overdue: number;
  };
}

const INDEX = API_ROUTES.tasks;
const SHOW = API_ROUTES.task;
const LEAVE = API_ROUTES.task_leave;
const COMPLETE = API_ROUTES.task_complete;
const START = API_ROUTES.task_start;
const MOVE = API_ROUTES.task_move;
const UPDATE = API_ROUTES.task;
const DELETE = API_ROUTES.task;
const QUICK_CARDS_COUNT = API_ROUTES.tasks_count;

export interface ITaskService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITask> | IRestApiError>;
  add(data: ITaskStore): Promise<IRestApiResource<ITask> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<ITask> | IRestApiError>;
  leave(_id: string): Promise<IRestApiResource<ITask> | IRestApiError>;
  complete(_id: string): Promise<IRestApiResource<ITask> | IRestApiError>;
  start(_id: string): Promise<IRestApiResource<ITask> | IRestApiError>;
  move(
    _id: string,
    data: any
  ): Promise<IRestApiResource<ITask> | IRestApiError>;
  getQuickCardsCount(
    query?: Array<IUrlQuery>
  ): Promise<ITaskQuickCardsCount | IRestApiError>;
  update(
    _id: string,
    data: any
  ): Promise<IRestApiResource<ITask> | IRestApiError>;
  delete(
    _id: string,
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITask> | IRestApiError>;
}

const taskService: ITaskService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async add(data: ITaskStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(INDEX), "post", formData);
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: any) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async getQuickCardsCount(query?: Array<IUrlQuery>) {
    let queryString = query ? `?${queryToString(query)}` : "";
    return http(`${getRoute(QUICK_CARDS_COUNT)}${queryString}`, "get");
  },

  async leave(_id: string) {
    return http(`${getRoute(LEAVE).replace(":_id", _id.toString())}`, "get");
  },

  async complete(_id: string) {
    return http(`${getRoute(COMPLETE).replace(":_id", _id.toString())}`, "get");
  },

  async start(_id: string) {
    return http(`${getRoute(START).replace(":_id", _id.toString())}`, "get");
  },

  async move(_id: string, data: any) {
    let formData = generateFormData(new FormData(), data);
    return http(
      `${getRoute(MOVE).replace(":_id", _id.toString())}`,
      "post",
      formData
    );
  },
};

export { taskService };
