import { Abilities, PermissionsType } from "./PermissionsGate";

export const TEMPLATE: {
  [key in PermissionsType]: {
    title: string;
    abilities: Array<Abilities>;
  };
} = {
  settings: {
    title: "PERMISSION.TITLE.SETTINGS",
    abilities: ["create", "read", "update", "delete"],
  },
  vacancies: {
    title: "PERMISSION.TITLE.VACANCIES",
    abilities: ["create", "read", "update", "delete"],
  },
  mailboxes: {
    title: "PERMISSION.TITLE.MAILBOX",
    abilities: ["create", "read", "update", "delete"],
  },
  surveys: {
    title: "PERMISSION.TITLE.SURVEY",
    abilities: ["create", "read", "update", "delete"],
  },
  logs: {
    title: "PERMISSION.TITLE.LOGS",
    abilities: ["create", "read", "update", "delete"],
  },
  time_records: {
    title: "PERMISSION.TITLE.TIMERECORDS",
    abilities: ["create", "read", "update", "delete"],
  },
  time_record_switch: {
    title: "PERMISSION.TITLE.TIMERECORDS.SWITCH",
    abilities: ["create", "update"],
  },
  reports: {
    title: "PERMISSION.TITLE.REPORTS",
    abilities: ["create", "read", "update", "delete"],
  },
  exports: {
    title: "PERMISSION.TITLE.EXPORTS",
    abilities: ["create", "read", "update", "delete"],
  },
  accounting: {
    title: "PERMISSION.TITLE.ACCOUNTING",
    abilities: ["create", "read", "update", "delete"],
  },
  statistics: {
    title: "PERMISSION.TITLE.STATISTICS",
    abilities: ["read"],
  },
  cases: {
    title: "PERMISSION.TITLE.CASES",
    abilities: ["create", "read", "update", "delete"],
  },
  offers: {
    title: "PERMISSION.TITLE.OFFERS",
    abilities: ["create", "read", "update", "delete"],
  },
  incoming_offers: {
    title: "PERMISSION.TITLE.INCOMINGOFFERS",
    abilities: ["create", "read", "update", "delete"],
  },
  contracts: {
    title: "PERMISSION.TITLE.CONTRACTS",
    abilities: ["create", "read", "update", "delete", "sign"],
  },
  case_tradingplans: {
    title: "PERMISSION.TITLE.CASES.TRADINGPLANS",
    abilities: ["create", "read", "update", "delete"],
  },
  case_evalreports: {
    title: "PERMISSION.TITLE.CASES.EVALREPORTS",
    abilities: ["create", "read", "update", "delete"],
  },
  activity_payments: {
    title: "PERMISSION.TITLE.ACTIVITY.PAYMENTS",
    abilities: ["create", "read", "update", "delete"],
  },
  case_timerecords: {
    title: "PERMISSION.TITLE.CASES.TIMERECORDS",
    abilities: ["create", "read", "update", "delete"],
  },
  users: {
    title: "PERMISSION.TITLE.USER",
    abilities: ["create", "read", "update", "delete"],
  },
  chat: {
    title: "PERMISSION.TITLE.CHAT",
    abilities: ["create", "read", "update", "delete", "remove_user", "leave"],
  },
  message: {
    title: "PERMISSION.TITLE.MESSAGE",
    abilities: ["create", "read", "update", "delete"],
  },
  todos: {
    title: "PERMISSION.TITLE.TODO",
    abilities: ["create", "read", "update", "delete"],
  },
  notes: {
    title: "PERMISSION.TITLE.NOTES",
    abilities: ["create", "read", "update", "delete"],
  },
  documents: {
    title: "PERMISSION.TITLE.DOCUMENTS",
    abilities: ["create", "read", "update", "delete"],
  },
  guidelines: {
    title: "PERMISSION.TITLE.GUIDELINES",
    abilities: ["create", "read", "update", "delete"],
  },
  values: {
    title: "PERMISSION.TITLE.VALUES",
    abilities: ["create", "read", "update", "delete"],
  },
  knowledgebase: {
    title: "PERMISSION.TITLE.KNOWLEDGEBASE",
    abilities: ["create", "read", "update", "delete"],
  },
  communes: {
    title: "PERMISSION.TITLE.COMMUNES",
    abilities: ["create", "read", "update", "delete"],
  },
  time_track_user: {
    title: "PERMISSION.TITLE.TIME_TRACK_USER",
    abilities: ["create", "read", "update", "delete"],
  },
  user_payment: {
    title: "PERMISSION.TITLE.USER_PAYMENT",
    abilities: ["create", "read", "update", "delete"],
  },
  contract_templates: {
    title: "PERMISSION.TITLE.CONTRACT_TEMPLATE",
    abilities: ["create", "read", "update", "delete"],
  },
  projects: {
    title: "PERMISSION.TITLE.PROJECTS",
    abilities: ["create", "read", "update", "delete"],
  },
  tasks: {
    title: "PERMISSION.TITLE.TASKS",
    abilities: ["create", "read", "update", "delete"],
  },
  calls: {
    title: "PERMISSION.TITLE.CALLS",
    abilities: ["create", "read", "update", "delete"],
  },
};

export type PermissionStructureType = {
  [key in PermissionsType]: {
    readonly title: string;
    abilities: Array<Abilities>;
  };
};
