import React, { FC, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Attach from "./attach";
import TextArea from "./textarea";
import Sender from "./sender";
import { IMessage } from "../messenger";

const useStyle = makeStyles((theme: Theme) => ({
  composerContainer: {
    borderTop: "0.5px solid #E6E6E6",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  composerReplyArea: {
    minHeight: 40,
  },
  composerArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",

    "& > div": {
      height: "100%",
      outline: "1px solud black",
    },
  },
  composerAttachingArea: {
    flexGrow: 0.27,
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  composerTextingArea: {
    flexGrow: 10,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  composerSendingArea: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));

export type MessengerComposerType = {
  activeReplyMessage?: IMessage;
  onReplyClose: () => void;
  onReplyClick: () => void;
  onFileUpload: (file: File[]) => void;
  onVoiceUpload: (blobUrl: string, blob: Blob) => void;
  sendMessage: Function;
  active: boolean;
};

const Composer: FC<MessengerComposerType> = (props) => {
  const {
    activeReplyMessage,
    onReplyClose,
    active,
    onReplyClick,
    onFileUpload,
    onVoiceUpload,
  } = props;
  const classes = useStyle();
  const [message, setMessage] = useState<string>("");

  const onSend = () => {
    props.sendMessage(message);
    setMessage("");
  };

  const getMessage = (message: string) => {
    setMessage(message);
  };

  return active ? (
    <Grid container className={classes.composerContainer}>
      {activeReplyMessage && (
        <Grid className={classes.composerReplyArea}>
          <ReplyMessage
            onClick={onReplyClick}
            onClose={onReplyClose}
            message={activeReplyMessage}
          />
        </Grid>
      )}

      <Grid className={classes.composerArea}>
        <Grid className={classes.composerAttachingArea}>
          <Attach onFileUpload={onFileUpload} />
        </Grid>
        <Grid className={classes.composerTextingArea}>
          <TextArea message={message} sentMessage={getMessage} />
        </Grid>
        <Grid className={classes.composerSendingArea}>
          <Sender
            onSend={onSend}
            onEmojiChange={(emoji: string) => {
              setMessage((prev) => prev + emoji);
            }}
            onVoiceUpload={onVoiceUpload}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const ReplyMessage: FC<{
  message: IMessage;
  onClose: () => void;
  onClick: () => void;
}> = (props) => {
  const { message, onClick, onClose } = props;
  const { created_by, documents } = message;

  return (
    <Box
      sx={{
        cursor: "pointer",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      }}
      onClick={() => onClick()}
    >
      <Box
        sx={{
          flexGrow: 0.27,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#767C91",
            fontSize: "22.7px",

            pl: 1.5,
            pr: 1,
            py: 0.5,
            ml: -1.5,
          }}
        >
          <i
            className="fi fi-sr-reply-all"
            style={{
              lineHeight: "0.5em",
              verticalAlign: "-0.15em",
              display: "inline-flex",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 10,
          display: "flex",
          gap: 1.5,
        }}
      >
        {documents && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              sx={{
                borderRadius: "8px",
              }}
              variant={"rounded"}
              src={
                documents[0].type === "img"
                  ? process.env.REACT_APP_FILES_BASE_URL +
                    `/storage/${documents[0]._id}/${documents[0].name}`
                  : documents[0].name
              }
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography fontSize={16} color={"#3DCA81"}>
              {created_by?.firstname}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: 4,
              // flexDirection: files ? "column" : "row",
            }}
          >
            <Box
              sx={{
                overflowY: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {/*<Typography fontSize={16} color={"#3B3B3B"}>*/}
              {/*  {message.message ??*/}
              {/*    (documents?[0].type === "mp3" ? "voice" : documents?[0].name)}*/}
              {/*</Typography>*/}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 0.27,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => onClose()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#767C91",
            mr: -1,
          }}
        >
          <i
            className="fi fi-sr-cross"
            style={{
              lineHeight: "0.5em",
              verticalAlign: "-0.15em",
              display: "inline-flex",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Composer;
