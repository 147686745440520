import React from "react";
import {
  IUrlQuery,
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { IModule } from "./module.service";
import { IFile } from "./file.service";

export interface ICall {
  _id: string;
  name: string;
  slug: string;
  description: string;
  address: string;
  phone: string;
  vat_id: string;
  rn: string;
  an: string;
  currency: string;
  email: string;
  avatar: string;
  template?: string;
  modules: Array<IModule>;
  active: number;
  file: IFile | undefined;
}

const INDEX = API_ROUTES.calls;
const STAT = API_ROUTES.callstats;

export interface IBusinessService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ICall> | IRestApiError>;
  stats(queries?: Array<IUrlQuery>): Promise<any | IRestApiError>;
}

const callService: IBusinessService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },
  async stats(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";
    return http(`${getRoute(STAT)}${queriesString}`, "get");
  },
};

export { callService };
