import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ISetting } from "../../../services/setting.service";
import { FormattedMessage, useIntl } from "react-intl";
import * as setting from "../../../store/ducks/setting.duck";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: { [key: string]: string } | null;
  categories: Array<string>;
  goal: string;
  active: number;
};

const ShowQuestion = (props: FormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { goals } = useSelector((state: RootState) => state.settingReducer);
  const { question_categories } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [question, setQuestion] = useState<ISetting>();
  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  useEffect(() => {
    settingService
      .getOne(props._id, [
        {
          name: "type",
          value: "questions",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: question } = data;
        setQuestion(question);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .update(props._id, validated, [
        {
          name: "type",
          value: "questions",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        let { data: setting } = data;
        updateSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (question) {
      form.values.title = question.title;
      form.values.categories =
        question?.categories?.map((category) => category._id) ?? [];

      if (question?.goal?._id) form.values.goal = question.goal._id;

      form.values.active = question?.active;
    }
  }, [question]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"setting_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                {system_languages &&
                  system_languages.map((system_language) => {
                    return (
                      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                        {loaded && question ? (
                          <Controls.Input
                            name={`title_${system_language.short_code}`}
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            defaultValue={
                              question?.title[system_language.short_code]
                            }
                            onChange={(event) => {
                              if (!event.target.value) return;

                              if (!form.values.title) {
                                form.values.title = {};
                              }

                              form.values.title[system_language.short_code] =
                                event.target.value;

                              event.preventDefault();
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {system_language.short_code.toUpperCase()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Input
                              name={`title_${system_language.short_code}`}
                              label={intl.formatMessage({
                                id: "STANDARD.TITLE",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                    );
                  })}
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && question ? (
                    <Controls.MultipleSelect
                      name={"categories"}
                      formId={"categories"}
                      options={question_categories?.map((q_c) => {
                        return {
                          id: q_c._id,
                          title: q_c.title[intl.locale],
                        };
                      })}
                      defaultValue={question?.categories?.map(
                        (question_category) => question_category._id
                      )}
                      label={intl.formatMessage({
                        id: "SETTINGS.QUESTION.CATEGORIES",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.categories = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"categories"}
                        options={[]}
                        label={intl.formatMessage({
                          id: "SETTINGS.QUESTION.CATEGORIES",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && question ? (
                    <Controls.Select
                      name={"goal"}
                      formId={"goal"}
                      options={goals?.map((g) => {
                        return {
                          id: g._id.toString(),
                          title: g.title[intl.locale],
                        };
                      })}
                      defaultValue={question?.goal?._id}
                      label={intl.formatMessage({
                        id: "SETTINGS.QUESTION.RATIO",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.goal = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"goal"}
                        options={[]}
                        label={intl.formatMessage({
                          id: "SETTINGS.QUESTION.RATIO",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && question ? (
                    <Controls.Select
                      name={"status"}
                      formId={"status"}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({
                            id: "STANDARD.ACTIVE",
                          }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      defaultValue={question?.active}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"status"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowQuestion;
