import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { statisticService } from "../services";
import Snackbar from "./Snackbar";
import ReactApexChart from "react-apexcharts";
import { ICaseTotal } from "../services/statistic.service";
import NoData from "../partials/NoData";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const options = ["all", "month", "week"];

const ITEM_HEIGHT = 48;

const CaseChart = (props: { source: string }) => {
  const intl = useIntl();
  const [cases, setCases] = useState<ICaseTotal>({
    data: [],
    spentMoney: 0,
    spentTime: 0,
    total: 0,
  });
  const [focusTypeTitles, setFocusTypeTitles] = useState<Array<string>>([""]);
  const [focusTypeColors, setFocusTypeColors] = useState<Array<string>>([""]);
  const [focusTypeSeries, setFocusTypeSeries] = useState<Array<number>>([0]);
  const [spentTime, setSpentTime] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>(
    intl.formatMessage({
      id: "STANDARD.ALL",
    })
  );
  const [total, setTotal] = useState<number>(0);
  const open = Boolean(anchorEl);
  const { user: me } = useSelector(({ auth }: RootState) => auth);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };

  useEffect(() => {
    statisticService
      .getCasesTotal([{ name: "filter", value: filter }])
      .then((response) => {
        if ("error" in response) {
          // @ts-ignore
          if ("code" in response.error) {
            throw new Error(response.error.message);
          } else {
            throw new Error(response.error);
          }
        }

        setCases(response);

        if (response?.data) {
          let titles: Array<string> = [];
          let series: Array<number> = [];
          let colors: Array<string> = [];
          let _spentTime: string = "";

          response.data
            .slice(0, 3)
            .map(
              (_case: {
                title: { [x: string]: any };
                count: any;
                color: string;
              }) => {
                titles.push(_case.title[intl.locale] ?? "");
                series.push(_case.count ?? 0);
                colors.push(_case.color ?? "#000");
              }
            );

          let arr: Array<any> = [];

          if (response.data.length > 3) {
            arr = response.data
              .slice(3, response.data.length - 1)
              ?.map((x: { count: any }) => x.count);
          }

          let newArr = arr?.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );

          if (newArr) {
            series.push(newArr);
          }

          setFocusTypeSeries(series);
          titles.push(intl.formatMessage({ id: "CHART.OTHERS" }));
          colors.push("#FBB73C");
          setFocusTypeColors(colors);
          setFocusTypeTitles(titles);

          let used = response ? response.spentTime / 1000 : 0;
          let hour = Math.floor(used / 3600);
          _spentTime += hour >= 1 ? hour + "h " : "";

          setSpentTime(_spentTime);
        }
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [filter]);

  useEffect(() => {
    if (focusTypeSeries) setTotal(focusTypeSeries.reduce((a, b) => a + b, 0));
  }, [focusTypeSeries]);

  return (
    <Card
      sx={{
        padding: "0 20px",
        height: "316px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <>
        <CardHeader
          titleTypographyProps={{
            fontSize: 16,
          }}
          sx={{ padding: "12px 0 12px 0" }}
          title={intl.formatMessage({ id: "PERMISSION.TITLE.CASES" })}
          action={
            <>
              <IconButton
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                edge="start"
                color="inherit"
                disableFocusRipple={true}
                sx={{
                  borderRadius: 0,
                  padding: "8px 0 0 0",
                  "&.MuiButtonBase-root:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                aria-label="close"
              >
                <Typography
                  variant={"inherit"}
                  sx={{ fontSize: "13px", textTransform: "capitalize" }}
                >
                  {filter}
                </Typography>
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(filter)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === filter}
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      handleClose(option);
                    }}
                  >
                    {intl.formatMessage({
                      id: "STANDARD." + option.toUpperCase(),
                    })}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
        />
        {cases && focusTypeSeries && focusTypeTitles ? (
          <>
            {total && total > 0 ? (
              <>
                <CardContent sx={{ height: 160, padding: 0 }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <ReactApexChart
                      options={{
                        chart: { width: "100%" },
                        states: {
                          hover: {
                            filter: {
                              type: "none",
                            },
                          },
                        },
                        labels: focusTypeTitles,
                        colors: focusTypeColors,
                        fill: {
                          opacity: 1,
                          colors: focusTypeColors,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          show: false,
                        },
                        grid: {
                          padding: {
                            left: -9,
                            top: 0,
                          },
                        },
                        stroke: {
                          width: 0,
                        },
                        plotOptions: {
                          pie: {
                            donut: {
                              labels: {
                                show: true,
                                name: {
                                  color: undefined,
                                  formatter: function () {
                                    return "Total";
                                  },
                                },
                                value: {
                                  color: "#3DCA81",
                                  fontWeight: 700,
                                  fontFamily: "Poppins",
                                  fontSize: "24px",
                                  formatter: function (val: any) {
                                    return val;
                                  },
                                },
                                total: {
                                  show: true,
                                  label: "Total",
                                  color: "#666!important",
                                  fontSize: "13px",
                                  fontFamily: "Poppins",
                                  formatter: function (w: any) {
                                    return w.globals.seriesTotals.reduce(
                                      (a: any, b: any) => {
                                        return a + b;
                                      },
                                      0
                                    );
                                  },
                                },
                              },
                            },
                          },
                        },
                        tooltip: {
                          followCursor: true,
                          fillSeriesColor: false,
                          theme: "light",
                        },
                      }}
                      series={focusTypeSeries}
                      type="donut"
                      width="200px"
                    />
                  </Box>
                </CardContent>

                <CardActions sx={{ marginTop: "35px" }}>
                  {me?.role.slug !== "citizen" &&
                  me?.role.slug !== "social-worker" ? (
                    <Grid container spacing={2} mb={2}>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            minHeight: 60,
                            width: "100%",
                            backgroundColor: "#556EE6",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              height: 60,
                              justifyContent: "space-around",
                              padding: "10px",
                            }}
                          >
                            <Grid item sm={3} xs={3} md={3} lg={3} xl={3}>
                              <Box
                                sx={{
                                  width: 36,
                                  height: 36,
                                  background: "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <path
                                    d="M11.1945 22.9305C11.1945 23.17 11.0994 23.3996 10.9301 23.5689C10.7608 23.7382 10.5312 23.8333 10.2917 23.8333H3.06953C2.83009 23.8333 2.60047 23.7382 2.43117 23.5689C2.26186 23.3996 2.16675 23.17 2.16675 22.9305C2.16675 22.6911 2.26186 22.4615 2.43117 22.2922C2.60047 22.1229 2.83009 22.0278 3.06953 22.0278H10.2917C10.5312 22.0278 10.7608 22.1229 10.9301 22.2922C11.0994 22.4615 11.1945 22.6911 11.1945 22.9305Z"
                                    fill="#556EE6"
                                  />
                                  <path
                                    d="M3.06953 20.2222H8.48619C8.72562 20.2222 8.95525 20.1271 9.12455 19.9578C9.29385 19.7885 9.38897 19.5589 9.38897 19.3194C9.38897 19.08 9.29385 18.8504 9.12455 18.6811C8.95525 18.5118 8.72562 18.4167 8.48619 18.4167H3.06953C2.83009 18.4167 2.60047 18.5118 2.43117 18.6811C2.26186 18.8504 2.16675 19.08 2.16675 19.3194C2.16675 19.5589 2.26186 19.7885 2.43117 19.9578C2.60047 20.1271 2.83009 20.2222 3.06953 20.2222Z"
                                    fill="#556EE6"
                                  />
                                  <path
                                    d="M3.06953 16.6111H6.68063C6.92007 16.6111 7.14969 16.516 7.31899 16.3467C7.4883 16.1774 7.58341 15.9478 7.58341 15.7083C7.58341 15.4689 7.4883 15.2393 7.31899 15.07C7.14969 14.9007 6.92007 14.8055 6.68063 14.8055H3.06953C2.83009 14.8055 2.60047 14.9007 2.43117 15.07C2.26186 15.2393 2.16675 15.4689 2.16675 15.7083C2.16675 15.9478 2.26186 16.1774 2.43117 16.3467C2.60047 16.516 2.83009 16.6111 3.06953 16.6111Z"
                                    fill="#556EE6"
                                  />
                                  <path
                                    d="M13.0001 2.16666C10.1269 2.16666 7.3714 3.30802 5.33976 5.33967C3.30811 7.37131 2.16675 10.1268 2.16675 13C2.16675 13.0551 2.17397 13.1083 2.17487 13.1634C2.46159 13.0583 2.76417 13.003 3.06953 13H6.68064C7.12381 12.9985 7.56053 13.1061 7.95225 13.3134C8.34396 13.5207 8.67862 13.8212 8.92667 14.1885C9.17471 14.5557 9.32852 14.9784 9.37451 15.4192C9.4205 15.8599 9.35725 16.3053 9.19036 16.7158C9.56 16.8128 9.90468 16.9876 10.2014 17.2284C10.4981 17.4693 10.74 17.7707 10.9109 18.1125C11.0818 18.4543 11.1778 18.8287 11.1924 19.2105C11.2071 19.5924 11.1401 19.973 10.9959 20.3269C11.3569 20.419 11.6946 20.5859 11.9871 20.8167C12.2796 21.0475 12.5204 21.3372 12.6939 21.6669C12.8674 21.9966 12.9698 22.3591 12.9944 22.7309C13.019 23.1027 12.9652 23.4755 12.8367 23.8252C12.8917 23.8252 12.945 23.8333 13.0001 23.8333C15.8733 23.8333 18.6288 22.6919 20.6604 20.6603C22.692 18.6287 23.8334 15.8732 23.8334 13C23.8334 10.1268 22.692 7.37131 20.6604 5.33967C18.6288 3.30802 15.8733 2.16666 13.0001 2.16666V2.16666ZM16.3467 16.3466C16.1774 16.5158 15.9478 16.6109 15.7084 16.6109C15.469 16.6109 15.2394 16.5158 15.0701 16.3466L12.3618 13.6382C12.1925 13.469 12.0974 13.2394 12.0973 13V8.4861C12.0973 8.24667 12.1924 8.01704 12.3617 7.84774C12.531 7.67843 12.7606 7.58332 13.0001 7.58332C13.2395 7.58332 13.4691 7.67843 13.6384 7.84774C13.8077 8.01704 13.9029 8.24667 13.9029 8.4861V12.6262L16.3467 15.0701C16.5159 15.2394 16.611 15.4689 16.611 15.7083C16.611 15.9477 16.5159 16.1773 16.3467 16.3466Z"
                                    fill="#556EE6"
                                  />
                                </svg>
                              </Box>
                            </Grid>
                            <Grid item sm={9} xs={9} md={9} lg={9} xl={9}>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "60px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  padding: "0 0 0 10px",
                                }}
                              >
                                <Typography
                                  variant="inherit"
                                  sx={{ fontSize: "13px" }}
                                  color={"#fff"}
                                >
                                  {intl.formatMessage({
                                    id: "STANDARD.SPENTTIME",
                                    defaultMessage: "Spent time",
                                  })}
                                </Typography>
                                <Typography
                                  variant="inherit"
                                  sx={{ fontSize: "13px" }}
                                  color={"#fff"}
                                >
                                  {spentTime}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            minHeight: 60,
                            width: "100%",
                            backgroundColor: "#45C3ED",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              height: 60,
                              justifyContent: "space-around",
                              padding: "10px",
                            }}
                          >
                            <Grid item sm={3} xs={3} md={3} lg={3} xl={3}>
                              <Box
                                sx={{
                                  width: 36,
                                  height: 36,
                                  background: "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                }}
                              >
                                <svg
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.29175 3.77777C8.29175 1.78353 11.3233 0.166656 15.0626 0.166656C18.8019 0.166656 21.8334 1.78353 21.8334 3.77777C21.8334 5.772 18.8019 7.38888 15.0626 7.38888C11.3233 7.38888 8.29175 5.772 8.29175 3.77777ZM15.0626 9.19444C14.1354 9.19444 13.2561 9.09062 12.4527 8.91006C11.2258 7.99013 9.21529 7.38888 6.93758 7.38888C3.19828 7.38888 0.166748 9.00575 0.166748 11C0.166748 12.9942 3.19828 14.6111 6.93758 14.6111C10.6769 14.6111 13.7084 12.9942 13.7084 11C13.7084 10.9738 13.7021 10.9485 13.7012 10.9224H13.7084V12.8055C13.7084 14.7998 10.6769 16.4167 6.93758 16.4167C3.19828 16.4167 0.166748 14.7998 0.166748 12.8055V14.6111C0.166748 16.6053 3.19828 18.2222 6.93758 18.2222C10.6769 18.2222 13.7084 16.6053 13.7084 14.6111V16.4167C13.7084 18.4109 10.6769 20.0278 6.93758 20.0278C3.19828 20.0278 0.166748 18.4109 0.166748 16.4167V18.2222C0.166748 20.2164 3.19828 21.8333 6.93758 21.8333C10.6769 21.8333 13.7084 20.2164 13.7084 18.2222V18.15C14.1463 18.1969 14.5986 18.2222 15.0626 18.2222C18.8019 18.2222 21.8334 16.6053 21.8334 14.6111V12.8055C21.8334 14.7185 19.042 16.2803 15.514 16.4049V14.5994C19.042 14.4748 21.8334 12.9139 21.8334 11V9.19444C21.8334 11.1074 19.042 12.6692 15.514 12.7938V10.9883C19.042 10.8637 21.8334 9.30277 21.8334 7.38888V5.58332C21.8334 7.57756 18.8019 9.19444 15.0626 9.19444Z"
                                    fill="#45C3ED"
                                  />
                                </svg>
                              </Box>
                            </Grid>
                            <Grid item sm={9} xs={9} md={9} lg={9} xl={9}>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "60px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  padding: "0 0 0 10px",
                                }}
                              >
                                <Typography
                                  variant="inherit"
                                  sx={{ fontSize: "13px" }}
                                  color={"#fff"}
                                >
                                  {intl.formatMessage({
                                    id: "STANDARD.BUDGET",
                                    defaultMessage: "Budget",
                                  })}
                                </Typography>
                                <Typography
                                  variant="inherit"
                                  sx={{ fontSize: "13px" }}
                                  color={"#fff"}
                                >
                                  {cases?.spentMoney.toFixed(2)} kr
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </CardActions>
              </>
            ) : (
              <NoData height={280} />
            )}
          </>
        ) : (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: 240,
            }}
          >
            <CircularProgress />
          </Grid>
        )}
      </>
    </Card>
  );
};

export default CaseChart;
