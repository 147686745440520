import React, { FC, useEffect, useRef, useState } from "react";
import { Avatar, Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import Snackbar from "../../widgets/Snackbar";
import { businessService, callService } from "../../services";
import ReactApexChart from "react-apexcharts";
import { GridTable } from "../../partials/layout/GridTable";
import { useTable } from "../../hooks/useTable";
import { IBusiness } from "../../services/business.service";
import { IColumns } from "../../partials/layout/DataTable";
import moment from "moment";
import { ICall } from "../../services/call.service";

type Props = {
  lang: object;
  settings_loading: boolean;
};

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  submitBtn: {
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #66bb6a",
      color: "#66bb6a",
    },
  },
  antDropdown: {
    zIndex: 9999,
  },
  datePicker: {
    width: "100%",
    height: 51,
    borderRadius: 8,
    borderColor: "#E6E6E6",
    color: "rgba(0, 0, 0, 0.85)",
    "& .ant-picker-input > input::placeholder": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  antSpace: {
    width: "100%",
  },
  antSelect: {
    "& .ant-select-selector": {
      height: "51px!important",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff!important",
      border: "1px solid #E6E6E6!important",
      borderRadius: "8px!important",
      boxShadow: "unset!important",
    },
    "& .ant-select-selection-placeholder": {
      color: "#00000091",
      fontWeight: 400,
    },
    "& .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
  },
}));
const Calls: FC<Props> = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [stats, setStats] = useState<{
    total: number;
    answered: number;
    orphaned: number;
    missed: number;
  }>({ total: 0, answered: 0, orphaned: 0, missed: 0 });

  useEffect(() => {
    callService
      .stats()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        setStats(data);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  const { data, xhrLoading, updateQuery } = useTable<ICall>({
    fetch: callService.getAll,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const tableRef = useRef();
  const [init, setInit] = useState<boolean>(false);

  const columns: IColumns[] = [
    {
      field: "_id",
      valueGetter: (params) => {
        return params.row?._id.toString();
      },
      hide: true,
      headerName: "ID",
      minWidth: 50,
    },
    {
      field: "number",
      headerName: intl.formatMessage({ id: "STANDARD.NUMBER" }),
      renderCell: (params) => {
        return params.row?.from_number;
      },
      minWidth: 50,
    },
    {
      field: "customer",
      headerName: intl.formatMessage({ id: "STANDARD.CUSTOMER" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.customer}>
            <Typography variant="inherit">{params.row?.customer}</Typography>
          </Tooltip>
        );
      },
      minWidth: 140,
    },
    {
      field: "answered_by",
      headerName: intl.formatMessage({ id: "STANDARD.ANSWERED_BY" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.answered_by?.name}>
            <Typography variant="inherit">
              {params.row?.answered_by?.name}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 140,
    },
    {
      field: "smiley",
      headerName: intl.formatMessage({ id: "STANDARD.SMILEY" }),
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              params.row?.smiley?.rating
                ? params.row?.smiley?.comment
                  ? params.row?.smiley?.comment
                  : intl.formatMessage({ id: "STANDARD.NO_RATING" })
                : intl.formatMessage({ id: "STANDARD.EMPTY" })
            }
          >
            <Typography variant="inherit">
              {params.row?.smiley?.rating
                ? params.row?.smiley?.comment
                  ? params.row?.smiley?.comment
                  : intl.formatMessage({ id: "STANDARD.NO_RATING" })
                : intl.formatMessage({ id: "STANDARD.EMPTY" })}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 140,
    },
    {
      field: "customer_type",
      headerName: intl.formatMessage({ id: "STANDARD.CUSTOMER_TYPE" }),
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.customer_type}>
            <Typography variant="inherit">
              {params.row?.customer_type}
            </Typography>
          </Tooltip>
        );
      },
      minWidth: 140,
    },
    {
      field: "direction",
      headerName: intl.formatMessage({ id: "STANDARD.DIRECTION" }),
      renderCell: (params) => {
        return (
          <Typography variant="inherit">{params.row?.direction}</Typography>
        );
      },
      minWidth: 140,
    },
    {
      field: "status",
      headerName: intl.formatMessage({ id: "STANDARD.STATUS" }),
      renderCell: (params) => {
        return <Typography variant="inherit">{params.row?.status}</Typography>;
      },
      minWidth: 140,
    },
    {
      field: "started_at",
      headerName: intl.formatMessage({ id: "STANDARD.STARTED_AT" }),
      renderCell: (params) => {
        return (
          <Typography variant="inherit">
            {moment(params.row?.started_at).local().format("DD-MM-YYYY HH:mm")}
          </Typography>
        );
      },
      minWidth: 140,
    },
  ];

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  return (
    <>
      <Box>
        <Paper className={classes.table}>
          <Grid container spacing={2} mb={2} sx={{ alignItems: "center" }}>
            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              <Grid container>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Grid
                    sx={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      height: 80,
                      borderRadius: 2,
                      padding: 2,
                      cursor: "pointer",
                    }}
                  >
                    <Grid
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#867ee5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        style={{
                          fontSize: 17,
                          height: 20,
                          color: "#fff",
                        }}
                        className={`fi fi-sr-phone-call`}
                      ></i>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 13 }}
                        variant={"inherit"}
                      >
                        {intl.formatMessage({
                          id: "STANDARD.TOTAL",
                          defaultMessage: "Total",
                        })}{" "}
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 16 }}
                        variant={"inherit"}
                      >
                        {stats.total}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      backgroundColor: "#fff",
                      height: 80,
                      borderRadius: 2,
                      padding: 2,
                      cursor: "pointer",
                    }}
                  >
                    <Grid
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#93c154",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        style={{
                          fontSize: 17,
                          height: 20,
                          color: "#fff",
                        }}
                        className={`fi fi-sr-call-incoming`}
                      ></i>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 13 }}
                        variant={"inherit"}
                      >
                        {intl.formatMessage({
                          id: "STANDARD.ANSWERED",
                          defaultMessage: "Answered",
                        })}
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 16 }}
                        variant={"inherit"}
                      >
                        {stats.answered}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      backgroundColor: "#fff",
                      height: 80,
                      borderRadius: 2,
                      padding: 2,
                      cursor: "pointer",
                    }}
                  >
                    <Grid
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#4696ef",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        style={{
                          fontSize: 17,
                          height: 20,
                          color: "#fff",
                        }}
                        className={`fi fi-sr-phone-cross`}
                      ></i>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 13 }}
                        variant={"inherit"}
                      >
                        {intl.formatMessage({
                          id: "STANDARD.ORPHANED",
                          defaultMessage: "Orphaned",
                        })}
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 16 }}
                        variant={"inherit"}
                      >
                        {stats.orphaned}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      backgroundColor: "#fff",
                      height: 80,
                      borderRadius: 2,
                      padding: 2,
                      cursor: "pointer",
                    }}
                  >
                    <Grid
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: "#d65538",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        style={{
                          fontSize: 17,
                          height: 20,
                          color: "#fff",
                        }}
                        className={`fi fi-sr-call-missed`}
                      ></i>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 13 }}
                        variant={"inherit"}
                      >
                        {intl.formatMessage({
                          id: "STANDARD.MISSED",
                          defaultMessage: "Missed",
                        })}
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 500, fontSize: 16 }}
                        variant={"inherit"}
                      >
                        {stats.missed}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              <ReactApexChart
                options={{
                  chart: {
                    height: 390,
                    type: "radialBar",
                  },
                  plotOptions: {
                    radialBar: {
                      offsetY: 0,
                      startAngle: 0,
                      endAngle: 270,
                      hollow: {
                        margin: 5,
                        size: "30%",
                        background: "transparent",
                        image: undefined,
                      },
                      track: {
                        background: "#f5f5f5",
                        strokeWidth: "90%",
                        margin: 5,
                      },
                      dataLabels: {
                        name: {
                          show: false, // Display the name
                          fontSize: "14px",
                          offsetY: 5,
                        },
                        value: {
                          show: false,
                          formatter: function (val: number) {
                            return val + " ali";
                          },
                        },
                      },
                      barLabels: {
                        enabled: true,
                        useSeriesColors: true,
                        margin: 20,
                        fontSize: "16px",
                        formatter: function (seriesName: string) {
                          return seriesName;
                        },
                      },
                    },
                  },
                  colors: ["#d65538", "#4696ef", "#93c154", "#867ee5"],

                  labels: [
                    intl.formatMessage({
                      id: "STANDARD.MISSED",
                      defaultMessage: "Missed",
                    }),
                    intl.formatMessage({
                      id: "STANDARD.ORPHANED",
                      defaultMessage: "Orphaned",
                    }),
                    intl.formatMessage({
                      id: "STANDARD.ANSWERED",
                      defaultMessage: "Answered",
                    }),
                    intl.formatMessage({
                      id: "STANDARD.TOTAL",
                      defaultMessage: "Total",
                    }),
                  ],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        legend: {
                          show: false,
                        },
                      },
                    },
                  ],
                  stroke: {
                    lineCap: "round",
                  },
                  tooltip: {
                    enabled: true, // Enable tooltips
                    shared: true, // Show shared tooltip for multiple series
                    intersect: false, // Show tooltip on hover, not on intersection
                  },
                }}
                series={[
                  stats.missed,
                  stats.orphaned,
                  stats.answered,
                  stats.total,
                ]}
                type="radialBar"
                height={350}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              <ReactApexChart
                options={{
                  chart: {
                    type: "radialBar",
                  },
                  plotOptions: {
                    radialBar: {
                      startAngle: -90,
                      endAngle: 90,
                      hollow: {
                        size: "70%",
                      },
                      dataLabels: {
                        name: {
                          show: true,
                          fontSize: "22px",
                          color: "#333",
                        },
                        value: {
                          show: false, // Hide the percentage value
                        },
                        total: {
                          show: true,
                          fontSize: "25px",
                          label:
                            stats.total !== 0
                              ? Math.round(
                                  (100 * stats.answered) / stats.total
                                ) + "%"
                              : intl.formatMessage({
                                  id: "STANDARD.NOCALL",
                                  defaultMessage: "No Call",
                                }),
                          formatter: () =>
                            intl.formatMessage({
                              id: "STANDARD.PROGRESS",
                              defaultMessage: "Progress",
                            }),
                        },
                      },
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  fill: {
                    type: "solid",
                    colors: ["#867ee5"], // Progress bar color set to black
                  },
                  colors: ["#f5f5f5"], // Customize the progress bar color
                  labels: ["Progress"],
                }}
                series={[Math.round((100 * stats.answered) / stats.total)]}
                type="radialBar"
                height={300}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ mt: 2 }}>
          <GridTable
            loading={xhrLoading}
            skeleton={!init}
            pageSize={data?.per_page}
            count={data?.total}
            columns={columns}
            rows={data?.data ?? []}
            tableRef={tableRef}
            search={(queries) => updateQuery(queries)}
            query={(queries) => updateQuery(queries)}
          />
        </Box>
      </Box>
    </>
  );
};

export default Calls;
