// @ts-nocheck

import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { statisticService } from "../services";
import Snackbar from "./Snackbar";
import ReactApexChart from "react-apexcharts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IContractTotal } from "../services/statistic.service";
import NoData from "../partials/NoData";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";

const ITEM_HEIGHT = 48;

const ContractChart = () => {
  const options = ["all", "month", "week"];
  const intl = useIntl();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>(
    intl.formatMessage({
      id: "STANDARD.ALL",
    })
  );
  const [colors, setColors] = useState<Array<string>>([]);
  const [series, setSeries] = useState<Array<number>>([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };

  const [contracts, setContracts] = useState<IContractTotal>();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    statisticService
      .getContractsTotal([
        {
          name: "filter",
          value: filter,
        },
      ])
      .then((data) => {
        if ("error" in data) {
          // @ts-ignore
          if ("code" in data.error) {
            throw new Error(data.error.message);
          } else {
            throw new Error(data.error);
          }
        }

        setContracts(data);

        let _colors: Array<string> = [];
        let _series: Array<number> = [];

        _colors.push(
          data.accepted?.color,
          data.pending?.color,
          data.expired?.color
        );

        setColors(_colors);

        _series.push(
          data.accepted?.count,
          data.pending?.count,
          data.expired?.count
        );

        setSeries(_series);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [filter]);

  useEffect(() => {
    if (contracts) {
      setTotal(
        contracts.expired?.count +
          contracts.pending?.count +
          contracts.accepted?.count
      );
    }
  }, [contracts]);

  return (
    <Card sx={{ padding: "0 20px", height: "316px" }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 16,
        }}
        sx={{ padding: "12px 0 12px 0" }}
        title={intl.formatMessage({ id: "PERMISSION.TITLE.CONTRACTS" })}
        action={
          <>
            <IconButton
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              edge="start"
              color="inherit"
              disableFocusRipple={true}
              sx={{
                borderRadius: 0,
                padding: "8px 0 0 0",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-label="close"
            >
              <Typography
                variant={"inherit"}
                sx={{ fontSize: "13px", textTransform: "capitalize" }}
              >
                {filter}
              </Typography>
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(filter)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === filter}
                  sx={{
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleClose(option);
                  }}
                >
                  {intl.formatMessage({
                    id: "STANDARD." + option.toUpperCase(),
                  })}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      />
      {contracts ? (
        <>
          {total && series?.length > 0 ? (
            <>
              <CardContent sx={{ padding: 0 }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  <ReactApexChart
                    options={{
                      chart: {
                        width: "100%",
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            history.push(
                              `contracts?status=${config.w.config.labels[
                                config.dataPointIndex
                              ].toLowerCase()}`
                            );
                          },
                        },
                      },
                      labels: [
                        intl.formatMessage({
                          id: "STANDARD.ACCEPTED",
                          defaultMessage: "Accepted",
                        }),
                        intl.formatMessage({
                          id: "STANDARD.PENDING",
                          defaultMessage: "Pending",
                        }),
                        intl.formatMessage({
                          id: "STANDARD.EXPIRED",
                          defaultMessage: "Expired",
                        }),
                      ],
                      colors: colors?.length > 0 ? colors : [],
                      fill: {
                        opacity: 1,
                        colors: colors?.length > 0 ? colors : [],
                      },
                      dataLabels: {
                        enabled: true,
                        style: {
                          colors: ["#fff"],
                        },
                        formatter: function (val, opts) {
                          return opts.w.config.series[opts.seriesIndex];
                        },
                        background: {
                          enabled: true,
                          foreColor: "#000",
                          borderColor: "#fff",
                          borderRadius: 8,
                          padding: 5,
                          dropShadow: {
                            enabled: false,
                          },
                        },
                        dropShadow: {
                          enabled: false,
                        },
                      },
                      legend: {
                        show: true,
                        labels: {
                          colors: colors.length > 0 ? colors : [],
                        },
                        position: "bottom",
                        markers: {
                          width: 8,
                          height: 8,
                        },
                        itemMargin: {
                          horizontal: 15,
                          vertical: 0,
                        },
                      },
                      grid: {
                        padding: {
                          left: 0,
                          top: 0,
                        },
                      },
                      stroke: {
                        width: 5,
                      },
                      plotOptions: {
                        pie: {
                          expandOnClick: false,
                          donut: {
                            size: "45px",
                            labels: {
                              show: true,
                              name: {},
                              value: {
                                color: "#000",
                                fontWeight: 700,
                                fontFamily: "Poppins",
                                fontSize: "24px",
                              },
                              total: {
                                show: true,
                                label: "Total",
                                color: "#666",
                                fontSize: "13px",
                                fontFamily: "Poppins",
                                formatter: function (w: any) {
                                  return w.globals.seriesTotals.reduce(
                                    (a: any, b: any) => {
                                      return a + b;
                                    },
                                    0
                                  );
                                },
                              },
                            },
                          },
                        },
                      },
                      tooltip: {
                        enabled: false,
                      },
                    }}
                    series={series.length > 0 ? series : []}
                    type="donut"
                    width="350px"
                  />
                </Box>
              </CardContent>
            </>
          ) : (
            <NoData height={280} />
          )}
        </>
      ) : (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 240,
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Card>
  );
};

export default ContractChart;
