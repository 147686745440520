import React, { useCallback, useEffect, useState } from "react";
import { useTable } from "../../../hooks/useTable";
import { translationService } from "../../../services";
import { IColumns } from "../../../partials/layout/DataTable";
import { GridTable } from "../../../partials/layout/GridTable";
import { ITranslate } from "../../../services/translation.service";
import Controls from "app/widgets/uncontrolled";
import { useCellEdit } from "../../../hooks/useCellEdit";
import { Box, LinearProgress } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import * as i18n from "../../../store/ducks/i18n.duck";
import { useIntl } from "react-intl";
import { RootState } from "../../../store/store";

const TranslationForm = () => {
  const dispatch = useDispatch();

  const updateTranslate = useCallback(
    (s) => dispatch({ type: i18n.actionTypes.UpdateOne, payload: s }),
    [dispatch]
  );

  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const {
    data: tableData,
    setData: updateRow,
    refresh,
    xhrLoading,
    updateQuery,
  } = useTable<ITranslate>({
    fetch: translationService.getAll,
    query: [
      {
        name: "per_page",
        value: 5,
      },
    ],
  });
  const {
    newRow: rowData,
    startEdit,
    stopEdit,
    setNewData: cellUpdate,
    change: cellChange,
    xhrLoadings,
    reset,
  } = useCellEdit<ITranslate>({
    update: translationService.updateOne,
    handeChange: (params, value: string | number) => {
      const key: any = params.field;

      if (!rowData || !key) return;

      let newData = JSON.parse(JSON.stringify(rowData));
      newData.translations[key] =
        typeof value === "number" ? value.toString() : value;
      cellUpdate(newData);
    },
    rows: tableData,
    updateRow: updateRow,
    onDone: (data) => {
      updateTranslate(data);
    },
  });
  const [init, setInit] = useState<boolean>(false);
  const intl = useIntl();

  let columns: IColumns[] = [
    {
      field: "key",
      headerName: intl.formatMessage({ id: "STANDARD.KEY" }),
      valueGetter: (params) => {
        return params.row?.key;
      },
      minWidth: 250,
    },
  ];

  system_languages &&
    system_languages.map((column) => {
      columns = columns.concat([
        {
          field: column.short_code,
          headerName: column.short_code.toUpperCase(),
          renderCell: (params) => {
            return (
              <>
                {xhrLoadings.find(
                  (xhr) =>
                    xhr._id === params?.row?._id && xhr.field === params?.field
                ) && (
                  <Box sx={{ position: "absolute", left: 1, right: 1, top: 0 }}>
                    <LinearProgress />
                  </Box>
                )}
                {params.row?.translations[column.short_code]
                  ? params.row?.translations[column.short_code]
                  : ""}
              </>
            );
          },
          renderEditCell: (params) => {
            let value: ITranslate = params.row;

            return (
              <CustomCellEdit
                params={params}
                dataCy={column.short_code}
                cellChange={cellChange}
                value={
                  value.translations[column.short_code]
                    ? value.translations[column.short_code]
                    : ""
                }
              />
            );
          },
          minWidth: 250,
          editable: true,
        },
      ]);
    });

  console.log(columns);

  useEffect(() => {
    if (!xhrLoading) setInit(true);
  }, [xhrLoading]);

  return (
    <>
      <GridTable
        loading={xhrLoading}
        skeleton={!init}
        pageSize={tableData?.per_page}
        count={tableData?.total}
        columns={columns}
        rows={tableData?.data ?? []}
        filterModel={undefined}
        query={(queries) => updateQuery(queries)}
        search={(queries) => updateQuery(queries)}
        onCellEditStop={(params, event) => {}}
        onCellEditCommit={(params, event) => {
          if (!rowData) return;

          if ("key" in event) {
            if (event.key === "Escape") {
              return;
            }
          }

          let data: ITranslate = {
            _id: rowData._id,
            key: rowData.key,
            translations: rowData.translations,
          };

          stopEdit(data);
        }}
        onCellEditStart={(param, event) => {
          startEdit(param.row, { _id: param.row?._id, field: param.field });
        }}
        sx={{
          "& .MuiDataGrid-cell": {
            position: "relative",
          },
        }}
      />
    </>
  );
};

const CustomCellEdit = ({
  params,
  cellChange,
  value,
  dataCy,
}: {
  params: GridRenderEditCellParams;
  cellChange: (params: GridRenderEditCellParams, value: string) => void;
  value: string;
  dataCy: string;
}) => {
  const { id, field } = params;
  const apiRef = useGridApiContext();

  return (
    <Controls.Input
      type={"text"}
      dataCy={id}
      defaultValue={value}
      name={"title_" + dataCy}
      onKeyPress={(e) => {
        if (e.key == "Enter") {
          apiRef.current.setCellMode(id, field, "view");
        }
      }}
      onChange={(event) => {
        cellChange(params, event.target.value);
      }}
    />
  );
};

export default TranslationForm;
