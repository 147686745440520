/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import ErrorsPage from "app/pages/Errors/ErrorsPage";
import { LayoutContextProvider } from "_metronic";
import Layout from "_metronic/layout/Layout";
import * as routerHelpers from "app/router/RouterHelpers";
import AuthPage from "app/pages/Auth/AuthPage";
import LogoutPage from "app/pages/Auth/Logout";
import Home from "app/pages/Home";
import Cases from "app/pages/Cases";
import Calendar from "app/pages/Calendar";
import Todos from "app/pages/Todos";
import Settings from "app/pages/Settings";
import Partners from "app/pages/Partners";
import Vacancies from "app/pages/Vacancies";
import ChatPage from "app/pages/Chat";
import Mailbox from "app/pages/Mailbox";
import Surveys from "app/pages/Surveys";
import TimeTracks from "app/pages/TimeTracks";
import Offers from "app/pages/Offers";
import IncomingOffers from "app/pages/Offers/IncomingOffers";
import Logs from "app/pages/Logs";
import Guidelines from "app/pages/Guidelines";
import Reports from "app/pages/Reports";
import Exports from "app/pages/Exports";
import Users from "app/pages/Users";
import Role from "app/pages/Settings/Role";
import { default as NewUser } from "app/pages/Users/store";
import User from "app/pages/Users/show";
import Folders from "app/pages/Folders";
import Notes from "app/pages/Folders/Notes";
import Note from "app/pages/Folders/Notes/Page/show";
import Knowledgebase from "app/pages/Knowledgebase";
import KnowledgebaseItem from "app/pages/Knowledgebase/Item";
import ContractTemplates from "app/pages/Contracts/Templates";
import Contracts from "app/pages/Contracts";
import Case from "app/pages/Cases/show";
import Profile from "app/pages/Users";
import OfferNotes from "app/pages/Offers/OfferNotes";
import ArchiveCases from "app/pages/Cases/archive";
import Permission from "app/pages/Settings/Permission";
import TranslationForm from "app/pages/Settings/TranslationForm/index";
import Communes from "app/pages/Communes";
import ShowFolder from "app/pages/Folders/Page/show";
import ShowTodoPage from "app/pages/Todos/Page/show";
import Business from "app/pages/Business/index";
import ShowBusiness from "app/pages/Business/store";
import _Contracts from "../pages/_Contracts/index";
import CaseDashboard from "../pages/Cases/dashboard";
import Values from "../pages/Values";
import _Notifications from "../pages/Notifications";
import VacancyNotes from "../pages/Vacancies/VacancyNotes";
import Robots from "../pages/Todos/Robots";
import Projects from "../pages/Projects";
import Tasks from "../pages/Tasks";
import Accounting from "../pages/Accounting";
import TaxRates from "../pages/Accounting/TaxRates";
import Expenses from "../pages/Accounting/Expenses";
import Suppliers from "../pages/Accounting/Supplier";
import Templates from "../pages/Accounting/Templates";
import Invoice from "../pages/Accounting/invoice";
import KanbanBoard from "../pages/Tasks/kanban";
import Calls from "../pages/Calls";

export const Routes = withRouter(({ history }) => {
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/invoices/pdf" exact component={Invoice} />

        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <Switch>
              <Route path="/" exact component={Home} />

              <Route path="/carchive" exact component={ArchiveCases} />
              <Route path="/cases" exact component={Cases} />
              <Route path="/cases/dashboard" exact component={CaseDashboard} />
              <Route path="/cases/:id" exact component={Case} />

              <Route path="/settings" exact component={Settings} />

              <Route path="/todos" exact component={Todos} />
              <Route path="/robots" exact component={Robots} />
              <Route path="/todos/:id" exact component={ShowTodoPage} />

              <Route path="/calendar" component={Calendar} />
              <Route path="/projects" component={Projects} />
              <Route path="/tasks" component={Tasks} />
              <Route path="/kanban" component={KanbanBoard} />
              <Route path="/partners" component={Partners} />
              <Route path="/vacancies" exact component={Vacancies} />
              <Route
                path="/vacancies/:vacancyId/notes"
                exact
                component={VacancyNotes}
              />
              <Route path="/chats" component={ChatPage} />
              <Route path="/mailboxes" exact component={Mailbox} />
              <Route path="/surveys" exact component={Surveys} />
              <Route path="/notifications" exact component={_Notifications} />

              <Route
                path="/contract-templates"
                exact
                component={ContractTemplates}
              />
              <Route
                path="/contract-templates/:id/contracts"
                exact
                component={Contracts}
              />

              <Route path="/contracts" component={_Contracts} />
              <Route path="/timetracks" component={TimeTracks} />

              <Route path="/offers" exact component={Offers} />
              <Route
                path="/offers/:offerId/notes"
                exact
                component={OfferNotes}
              />
              <Route path="/incoming-offers" component={IncomingOffers} />

              <Route path="/logs" component={Logs} />
              <Route path="/guidelines" component={Guidelines} />
              <Route path="/values" component={Values} />
              <Route path="/reports" component={Reports} />
              <Route path="/exports" component={Exports} />

              <Route path="/users" exact component={Users} />
              <Route path="/users/add" exact component={NewUser} />
              <Route path="/users/:id" exact component={User} />

              <Route
                path="/translation_form"
                exact
                component={TranslationForm}
              />
              <Route path="/roles" exact component={Role} />
              <Route path="/permissions" exact component={Permission} />

              <Route path="/folders" exact component={Folders} />
              <Route path="/folders/:id" exact component={ShowFolder} />
              <Route path="/folders/:folderId/notes" exact component={Notes} />
              <Route
                path="/folders/:folderId/notes/:id"
                exact
                component={Note}
              />

              <Route path="/knowledgebase" exact component={Knowledgebase} />
              <Route
                path="/knowledgebase/:id/items"
                exact
                component={KnowledgebaseItem}
              />

              <Route path="/reports" exact component={Reports} />
              <Route path="/profile/:id" exact component={Profile} />
              <Route path="/communes" exact component={Communes} />

              <Route path="/business" exact component={Business} />
              <Route path="/addbusiness" exact component={ShowBusiness} />

              <Route path="/accounting" exact component={Accounting} />
              <Route path="/accounting/tax_rates" exact component={TaxRates} />
              <Route path="/accounting/expenses" exact component={Expenses} />
              <Route path="/accounting/suppliers" exact component={Suppliers} />
              <Route path="/accounting/templates" exact component={Templates} />
              <Route path="/calls" exact component={Calls} />

              <Redirect to="/error/error-v1" />
            </Switch>
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
