import React, { FC, useEffect, useState } from "react";
import Progress from "antd/lib/progress";
import { API_V } from "../../config/apiRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Snackbar from "../../widgets/Snackbar";
import { makeStyles } from "@material-ui/core";
import { documentService } from "../../services";
import { IFile } from "../../services/file.service";

interface Props {
  afterUpload: (file: IFile) => void;
  accept: string;
  avatar?: IFile | undefined;
}

const useStyles = makeStyles(() => ({
  antProgressInner: {
    position: "absolute",
    "& .ant-progress-inner": {
      width: "120px!important",
      height: "120px!important",
    },
  },
  formImage: {
    "& img": {
      objectFit: "cover",
    },
  },
}));

const ProfileUploader: FC<Props> = (props) => {
  const { afterUpload, accept, avatar } = props;
  const classes = useStyles();
  const input = React.useRef<any>(null);
  const { authToken, business } = useSelector(({ auth }: RootState) => auth);
  const [image, setImage] = useState<{
    id: string | null;
    url: string;
    noPhoto: boolean;
  }>({
    id: null,
    url: "/media/noavatar.png",
    noPhoto: true,
  });
  const [file, setFile] = React.useState<any>({
    loading: false,
    percent: 0,
    id: 0,
  });

  useEffect(() => {
    if (avatar) {
      setFile({
        ...avatar,
        loading: false,
        id: avatar._id,
      });

      setImage({
        id: avatar._id,
        url: avatar.base64,
        noPhoto: false,
      });
    }
  }, [avatar]);

  const onDrop = (e: any) => {
    let targetFile = e.target.files[0];

    setFile({
      ...file,
      loading: true,
      percent: 0,
      id: 0,
    });
    let formData = new FormData();
    formData.append("_env", process.env.NODE_ENV);
    formData.append("file", targetFile);
    sendFormData(formData);
  };

  const sendFormData = (data: any) => {
    let xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", function (e) {
      let percentComplete = (e.loaded / e.total) * 100;
      let percent = Math.round(percentComplete);
      setFile({ ...file, loading: true, percent });
    });

    xhr.addEventListener("load", function (e: any) {
      let response = e.target ? JSON.parse(e.target.response) : null;
      if ("error" in response) {
        Snackbar.error("Something went wrong");
      }
      const data = response.data;

      setFile({
        ...file,
        loading: false,
        id: response.data.id,
      });

      setImage({
        id: data._id,
        url:
          process.env.REACT_APP_FILES_BASE_URL +
          "/storage/" +
          data._id +
          "/" +
          data.name,
        noPhoto: false,
      });

      afterUpload(data);
    });

    xhr.open(
      "POST",
      process.env.REACT_APP_API_URL + "/" + API_V + "/documents",
      true
    );
    xhr.withCredentials = true;
    xhr.setRequestHeader("Authorization", "Bearer " + authToken);
    business && xhr.setRequestHeader("Business", business._id);
    xhr.send(data);
  };

  const onDelete = (id: string) => {
    documentService.delete(id).then((data) => {
      Snackbar.success("Deleted");
    });
    setFile({ loading: false, percent: 0, id: 0 });
    setImage({ id: null, url: "/media/noavatar.png", noPhoto: true });
  };

  return (
    <div
      className={`form-upload ${
        classes.formImage
      } position-relative d-flex align-items-center justify-content-center ${
        file.loading && "loading"
      }`}
      style={{
        width: 150,
        height: 150,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
      }}
    >
      {file.loading && (
        <Progress
          className={classes.antProgressInner}
          type="circle"
          percent={file.percent}
        />
      )}
      <img
        alt="avatar"
        src={image?.url}
        className="w-100 h-100"
        style={{ border: "1px solid #51b4ec" }}
      />
      <input
        ref={input}
        type="file"
        name="avatar"
        accept={accept}
        className="d-none"
        onClick={(event) => {
          event.currentTarget.value = "";
        }}
        onChange={onDrop}
      />
      <div
        style={{
          width: 75,
          display: "flex",
          justifyContent: "space-around",
          position: "absolute",
        }}
        className="actions"
      >
        <button
          className="feather feather-camera"
          onClick={(e) => {
            e.preventDefault();
            input.current.click();
          }}
        >
          <svg
            width="19.2"
            height="19.2"
            viewBox="0 0 450 376"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M319.626 205.213C319.626 257.834 276.969 300.491 224.348 300.491C171.728 300.491 129.071 257.834 129.071 205.213C129.071 152.593 171.728 109.936 224.348 109.936C276.969 109.936 319.626 152.593 319.626 205.213ZM290.31 205.213C290.31 241.643 260.778 271.175 224.348 271.175C187.919 271.175 158.387 241.643 158.387 205.213C158.387 168.784 187.919 139.252 224.348 139.252C260.778 139.252 290.31 168.784 290.31 205.213Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140.769 23.6361L124.765 57.7182L42.5259 57.7182C19.2224 57.7182 2.24121e-05 76.9825 2.03704e-05 100.337L0 333.346C-3.72583e-06 356.701 19.2224 375.965 42.5259 375.965L407.474 375.965C430.778 375.965 450 356.701 450 333.346L450 100.337C450 76.9825 430.778 57.7182 407.474 57.7182L325.397 57.7182L309.393 23.6361C309.087 23.0682 308.743 22.5212 308.365 21.9985C302.72 14.1986 286.931 0 264.202 0H185.96C163.231 0 147.442 14.1986 141.797 21.9985C141.419 22.5212 141.075 23.0682 140.769 23.6361ZM172.589 33.1925C169.298 35.1726 167.049 37.3802 165.997 38.6182L141.973 87.5757L140.797 86.9417V87.3659L42.5259 87.3659C35.0998 87.3659 29.5833 92.8945 29.5833 100.337L29.5832 333.346C29.5832 340.789 35.0998 346.317 42.5259 346.317L407.474 346.317C414.9 346.317 420.417 340.789 420.417 333.346L420.417 100.337C420.417 92.8945 414.9 87.3659 407.474 87.3659L309.365 87.3659V86.9418L308.189 87.5757L284.165 38.6182C283.113 37.3802 280.864 35.1726 277.574 33.1925C273.834 30.9419 269.307 29.3162 264.202 29.3162H185.96C180.855 29.3162 176.328 30.9419 172.589 33.1925Z"
              fill="white"
            />
          </svg>
        </button>
        {!image?.noPhoto && (
          <button
            className="feather feather-zoom-in"
            onClick={(e) => {
              e.preventDefault();

              window.open(image?.url);
            }}
          >
            <svg
              width="19.2"
              height="19.2"
              viewBox="0 0 445 450"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M92.4225 202.449C92.4225 193.537 99.6474 186.312 108.56 186.312H186.312V108.56C186.312 99.6474 193.537 92.4225 202.449 92.4225C211.362 92.4225 218.587 99.6474 218.587 108.56V186.312H296.339C305.251 186.312 312.476 193.537 312.476 202.449C312.476 211.362 305.251 218.587 296.339 218.587H218.587V296.339C218.587 305.251 211.362 312.476 202.449 312.476C193.537 312.476 186.312 305.251 186.312 296.339V218.587H108.56C99.6474 218.587 92.4225 211.362 92.4225 202.449Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M201.91 0C90.5719 0 0 90.5719 0 201.91C0 313.248 90.5719 403.82 201.91 403.82C251.065 403.82 296.162 386.158 331.214 356.858L419.186 445.491C420.533 446.894 422.146 448.016 423.931 448.79C425.716 449.564 427.637 449.975 429.582 449.999C431.527 450.023 433.457 449.66 435.261 448.931C437.064 448.202 438.705 447.121 440.086 445.752C441.468 444.382 442.563 442.752 443.309 440.955C444.054 439.158 444.434 437.231 444.427 435.286C444.421 433.34 444.027 431.416 443.269 429.624C442.511 427.833 441.404 426.21 440.013 424.85L352.302 336.478C384.317 300.734 403.82 253.561 403.82 201.91C403.82 90.5719 313.248 0 201.91 0ZM201.91 29.3229C297.401 29.3229 374.497 106.419 374.497 201.91C374.497 297.401 297.401 374.497 201.91 374.497C106.419 374.497 29.3229 297.401 29.3229 201.91C29.3229 106.419 106.419 29.3229 201.91 29.3229Z"
                fill="white"
              />
            </svg>
          </button>
        )}
        {!image?.noPhoto && (
          <button
            className="feather feather-trash-2"
            onClick={(e) => {
              e.preventDefault();

              image.id && onDelete(image.id);
            }}
          >
            <svg
              width="19.2"
              height="19.2"
              viewBox="0 0 396 450"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M242.647 364.706C233.713 364.706 226.471 357.463 226.471 348.529V167.647C226.471 158.713 233.713 151.471 242.647 151.471C251.581 151.471 258.824 158.713 258.824 167.647V348.529C258.824 357.463 251.581 364.706 242.647 364.706Z"
                fill="white"
              />
              <path
                d="M152.941 364.706C144.007 364.706 136.765 357.463 136.765 348.529V167.647C136.765 158.713 144.007 151.471 152.941 151.471C161.875 151.471 169.118 158.713 169.118 167.647L169.118 348.529C169.118 357.463 161.875 364.706 152.941 364.706Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M155.17 0C140.664 0 127.023 6.90362 118.434 18.593L88.8707 58.8235H16.1765C7.24245 58.8235 0 66.066 0 75C0 83.934 7.24245 91.1765 16.1765 91.1765H22.0588V389.706C22.0588 423.005 49.0534 450 82.3529 450H313.235C346.535 450 373.529 423.005 373.529 389.706V91.1764H379.412C388.346 91.1764 395.588 83.934 395.588 75C395.588 66.0659 388.346 58.8235 379.412 58.8235L302.115 58.8235L270.852 17.9095C262.227 6.62195 248.834 0 234.628 0H155.17ZM261.398 58.8235L245.145 37.5525C242.641 34.2754 238.752 32.3529 234.628 32.3529H155.17C150.958 32.3529 146.998 34.3572 144.504 37.7509L129.019 58.8235H261.398ZM54.4118 91.1765V389.706C54.4118 405.137 66.9215 417.647 82.3529 417.647H313.235C328.667 417.647 341.176 405.137 341.176 389.706V91.1765H54.4118Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default ProfileUploader;
