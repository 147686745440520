import React, { FC, useState } from "react";
import {
  Avatar,
  Dialog,
  DialogContent,
  ImageList,
  ImageListItem,
  Slide,
} from "@mui/material";
import { IFile } from "../../../../services/file.service";
import { TransitionProps } from "@mui/material/transitions";

const Media: FC<{
  items: Array<IFile>;
  onClick?: (id: string) => void;
}> = (props) => {
  const { items, onClick } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [source, setSource] = useState<string>("");

  const handleMedia = (source: string) => {
    setSource(source);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ImageList sx={{ width: "100%", p: 0 }} cols={3}>
        {items.map((item) => (
          <ImageListItem
            sx={{ width: "100%", cursor: "pointer" }}
            key={item._id}
            onClick={() => handleMedia(`/storage/${item._id}/${item.name}`)}
          >
            <img
              onClick={() => {
                onClick && onClick(item._id);
              }}
              width={16}
              height={16}
              src={
                process.env.REACT_APP_FILES_BASE_URL +
                `/storage/${item._id}/${item.name}`
              }
              srcSet={
                process.env.REACT_APP_FILES_BASE_URL +
                `/storage/${item._id}/${item.name}`
              }
              alt={item.name}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
            }}
            alt={""}
            variant={"square"}
            src={source}
          ></Avatar>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Media;
