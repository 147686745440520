import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Controls from "app/widgets/uncontrolled";
import * as _setting from "app/store/ducks/setting.duck";
import { Skeleton } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { useForm } from "app/hooks/useForm";
import { RootState } from "app/store/store";
import Snackbar from "app/widgets/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { businessService, IBusiness } from "app/services/business.service";
import { FormattedMessage, useIntl } from "react-intl";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { moduleService, timeTrackService } from "app/services";
import { IModule } from "../../services/module.service";
import { Ii18nState } from "../../store/ducks/i18n.duck";
import Box from "@mui/material/Box";
import Uploader from "./uploader";
import * as auth from "../../store/ducks/auth.duck";
import { getAccessToken, getBusiness } from "../../lib/http";
import { ITimezone } from "../../services/timeTrack.service";

interface IFormProps {
  id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  name: string;
  description: string;
  address: string;
  phone: string;
  vat_id: string;
  relatel_token: string;
  timezone: string;
  rn: string;
  an: string;
  currency: string;
  email: string;
  active: number;
  modules: Array<string>;
  file: string;
};

const useStyle = makeStyles(() => ({
  input: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          top: 0,
          border: "1px solid #E6E6E6!important",
          borderRadius: 6,
          "& legend": {
            display: "none!important",
          },
        },
      },
    },
  },
  container: {
    width: "100%",
    padding: "0px",
    display: "flex",
    flexFlow: "column wrap",
    height: 250, // set the height limit to your liking
  },
  item: {
    width: "auto",
  },
}));

const ShowBusiness = (props: IFormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector((state: RootState) =>
    Boolean(state.settingReducer && state.permission)
  );
  const [business, setBusiness] = useState<IBusiness>();
  const [checked, setChecked] = useState([""]);
  const [modules, setModules] = useState<Array<IModule>>([]);
  const { user } = useSelector(({ auth }: RootState) => auth);
  const { business: _business } = useSelector(({ auth }: RootState) => auth);
  const [timezones, setTimezones] = useState<ITimezone[]>([]);

  const { currencies } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    businessService
      .getOne(props.id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: business } = data;
        setBusiness(business);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props.id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    businessService
      .update(props.id, validated)
      .then(async (data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        props.setDialogue(false);
        props.onDone();
        form.clear();
        const { data: business } = data;

        if (business._id === _business?._id) {
          dispatch(auth.actions.updateBusiness(business));
        }

        if (user) {
          const token = getAccessToken(); // Replace with your actual token

          await fetch(`/api/v1/users/${user?._id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Business: getBusiness(),
            },
          }).then(async (data) => {
            const res = await data.json();

            dispatch(auth.actions.fulfillUser(res.data));
          });
        }
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    moduleService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: modules } = data;
        setModules(modules);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);
  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const classes = useStyle();

  useEffect(() => {
    if (business) {
      form.values.name = business?.name;
      form.values.description = business.description;
      form.values.address = business.address;
      form.values.phone = business.phone;
      form.values.vat_id = business.vat_id;
      form.values.relatel_token = business.relatel_token;
      form.values.timezone = business.timezone?.id.toString();
      form.values.rn = business.rn;
      form.values.an = business.an;
      form.values.currency = business.currency;
      form.values.email = business.email;
      form.values.active = 1;
      form.values.modules = business.modules.map((m) => m._id);
      form.values.file = business.file?._id ?? "";
    }
  }, [business]);

  useEffect(() => {
    timeTrackService
      .timezones()
      .then((data) => {
        if ("error" in data) throw new Error(data.error.message);

        setTimezones(data);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "rgb(245, 245, 245)",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            sx={{ flex: 1, color: "#000000" }}
            variant="h6"
            component="div"
          >
            <FormattedMessage
              id={"BUSINESS.ADD"}
              defaultMessage={"BUSINESS.ADD"}
            />
          </Typography>
          <Button
            autoFocus
            color="inherit"
            type={"submit"}
            form={"business_form"}
            sx={{
              backgroundColor: "#0D99FF",
              borderRadius: 2,
              color: "#ffffff",
            }}
          >
            <FormattedMessage
              id={"DIALOGUE.SAVE"}
              defaultMessage={"DIALOGUE.SAVE"}
            />
          </Button>
        </Toolbar>
      </Box>
      <DialogContent>
        <form
          id={"business_form"}
          onSubmit={(e) => form.handleSubmit(e, handleSave)}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage id={"AVATAR"} defaultMessage={"AVATAR"} />
                  </Typography>
                  <Uploader
                    afterUpload={(file_id: string) =>
                      (form.values.file = file_id)
                    }
                    accept={".png,.jpg,.jpeg,"}
                    avatar={business?.file}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"business"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"ECOMMERCE.COMMON.BUSINESS"}
                      defaultMessage={"ECOMMERCE.COMMON.BUSINESS"}
                    />
                  </Typography>
                  <Controls.Input
                    name={"name"}
                    label={""}
                    defaultValue={business?.name}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.name = event.target.value;
                    }}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"name"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"CASE.INFORMATION.EMAIL"}
                      defaultMessage={"CASE.INFORMATION.EMAIL"}
                    />
                  </Typography>
                  <Controls.Input
                    defaultValue={business?.email}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.email = event.target.value;
                    }}
                    name={"email"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.VATID"}
                      defaultMessage={"STANDARD.VATID"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.vat_id = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={business.vat_id}
                    name={"vat_id"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"USERS.PRN"}
                      defaultMessage={"USERS.PRN"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.rn = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={business.rn}
                    name={"rn"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"USERS.PAN"}
                      defaultMessage={"USERS.PAN"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.an = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={business.an}
                    name={"an"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.RELATEL_TOKEN"}
                      defaultMessage={"Relatel token"}
                    />
                  </Typography>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.relatel_token = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={business.relatel_token}
                    name={"relatel_token"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.TIMEZONE"}
                      defaultMessage={"Timezone"}
                    />
                  </Typography>
                  <Controls.Select
                    options={
                      timezones &&
                      timezones.map((timezone) => {
                        return {
                          id: timezone.id,
                          title: timezone.title ?? "",
                        };
                      })
                    }
                    formId={"timezone_pick"}
                    name={"timezone"}
                    label={""}
                    defaultValue={business.timezone?.id}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.timezone = event.target.value;
                    }}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"relatel_token"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.DEFAULT_CURRENCY",
                        defaultMessage: "Default currency",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Select
                    options={
                      currencies &&
                      currencies.map((currency) => {
                        return {
                          id: currency.slug,
                          title: currency.currency_title ?? "",
                        };
                      })
                    }
                    formId={"status_pick"}
                    defaultValue={business.currency}
                    name={"status"}
                    label={""}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.currency = event.target.value;
                    }}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"description"}
                    label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={6} xl={6}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.ADDRESS",
                        defaultMessage: "Address",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.address = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={business.address}
                    name={"an"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={3} xl={3}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Divider sx={{ mb: 1 }}>
                    <Chip
                      label={intl.formatMessage({
                        id: "STANDARD.PHONE",
                        defaultMessage: "Phone",
                      })}
                      sx={{ backgroundColor: "#0D99FF", color: "#ffffff" }}
                    />
                  </Divider>
                  <Controls.Input
                    onChange={(e) => {
                      if (!e.target.value) return null;

                      form.values.phone = e.target.value;
                      e.preventDefault();
                    }}
                    defaultValue={business.phone}
                    name={"phone"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"email"}
                    label={""}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>

            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && business ? (
                <Box className={classes.input}>
                  <Typography variant={"body1"} sx={{ mb: 0.5 }}>
                    <FormattedMessage
                      id={"STANDARD.DESCRIPTION"}
                      defaultMessage={"STANDARD.DESCRIPTION"}
                    />
                  </Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    defaultValue={business?.description}
                    onChange={(event) => {
                      if (!event.target.value) return;

                      form.values.description = event.target.value;
                    }}
                    multiline={true}
                    rows={4}
                    name={"description"}
                    label={""}
                  />
                </Box>
              ) : (
                <Skeleton width={"100%"}>
                  <Controls.Input
                    name={"description"}
                    label={intl.formatMessage({ id: "STANDARD.DESCRIPTION" })}
                    onChange={() => {}}
                  />
                </Skeleton>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              {loaded && business ? (
                <List className={classes.container}>
                  {modules?.map((module) => {
                    const labelId = `checkbox-list-label-${module}`;
                    return (
                      <ListItem
                        key={module._id}
                        disablePadding
                        className={classes.item}
                      >
                        <ListItemButton
                          sx={{ padding: "0px 2px" }}
                          role={undefined}
                          onClick={() => {
                            handleToggle(module._id);
                            if (form.values.modules.includes(module._id)) {
                              form.values.modules = form.values.modules.filter(
                                (m) => m !== module._id
                              );
                            } else {
                              form.values.modules.push(module._id);
                            }
                          }}
                          dense
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 34,
                              "& .Mui-checked": {
                                color: "#0D99FF!important",
                              },
                            }}
                          >
                            <Checkbox
                              edge="start"
                              defaultChecked={Boolean(
                                business?.modules.find(
                                  (m) => m._id === module._id
                                )
                              )}
                              tabIndex={-1}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={
                              module.title.charAt(0).toUpperCase() +
                              module.title.slice(1)
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Skeleton height={200} width={"100%"}>
                  <List />
                </Skeleton>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = ({
  i18n,
  settingReducer,
}: {
  i18n: Ii18nState;
  settingReducer: any;
}) => {
  return {
    lang: i18n.lang,
    businesses: [],
    settings_loading: settingReducer.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSettings: () => dispatch(_setting.actions.get()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowBusiness);
