// @ts-nocheck

import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { statisticService } from "../services";
import Snackbar from "./Snackbar";
import { IOfferTotal } from "../services/statistic.service";
import NoData from "../partials/NoData";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";

const options = ["all", "month", "week"];
const ITEM_HEIGHT = 48;

const OfferChart = () => {
  const intl = useIntl();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>(
    intl.formatMessage({
      id: "STANDARD.ALL",
    })
  );
  const [titles, setTitles] = useState<Array<string>>([]);
  const [colors, setColors] = useState<Array<string>>([]);
  const [counts, setCounts] = useState<Array<number>>([]);
  const [total, setTotal] = useState<number>(0);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };
  const [offers, setOffers] = useState<IOfferTotal>();

  useEffect(() => {
    statisticService
      .getOffersTotal([
        {
          name: "filter",
          value: filter,
        },
      ])
      .then((ret) => {
        if ("error" in ret) {
          // @ts-ignore
          if ("code" in ret.error) {
            throw new Error(ret.error.message);
          } else {
            throw new Error(ret.error);
          }
        }

        setOffers(ret);

        let _titles: Array<string> = [];
        let _colors: Array<string> = [];
        let _counts: Array<number> = [];

        _titles.push("Accepted", "Canceled");

        _counts.push(ret.offer_accepted?.count, ret.offer_canselled?.count);

        _colors.push(ret.offer_accepted?.color, ret.offer_canselled?.color);

        setTitles(_titles);
        setColors(_colors);
        setCounts(_counts);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [filter]);

  useEffect(() => {
    if (counts) setTotal(counts.reduce((a, b) => a + b, 0));
  }, [counts]);

  return (
    <Card sx={{ padding: "0 20px", height: "316px" }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 16,
        }}
        sx={{ padding: "12px 0 0 0" }}
        title={intl.formatMessage({ id: "PERMISSION.TITLE.SURVEY" })}
        action={
          <>
            <IconButton
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              edge="start"
              color="inherit"
              disableFocusRipple={true}
              sx={{
                borderRadius: 0,
                padding: "8px 0 0 0",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-label="close"
            >
              <Typography
                variant={"inherit"}
                sx={{ fontSize: "13px", textTransform: "capitalize" }}
              >
                {filter}
              </Typography>
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(filter)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === filter}
                  sx={{
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleClose(option);
                  }}
                >
                  {intl.formatMessage({
                    id: "STANDARD." + option.toUpperCase(),
                  })}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      />
      <>
        {offers ? (
          <>
            {total > 0 ? (
              <CardContent sx={{ padding: 0 }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  {counts.length > 0 && (
                    <ReactApexChart
                      options={{
                        chart: {
                          width: "100%",
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                              history.push(
                                `offers?status=${config.w.config.labels[
                                  config.dataPointIndex
                                ]
                                  .split(" ")
                                  .join("-")
                                  .toLowerCase()}`
                              );
                            },
                          },
                        },
                        labels: titles.length > 0 ? titles : [],
                        colors: colors.length > 0 ? colors : [],
                        fill: {
                          opacity: 1,
                          colors: colors,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          show: true,
                          labels: {
                            colors: colors.length > 0 ? colors : [],
                          },
                          position: "bottom",
                          markers: {
                            width: 8,
                            height: 8,
                          },
                          itemMargin: {
                            horizontal: 10,
                            vertical: 0,
                          },
                        },

                        grid: {
                          padding: {
                            left: 0,
                            top: 0,
                          },
                        },
                        stroke: {
                          width: 5,
                          lineCap: "round",
                        },
                        plotOptions: {
                          pie: {
                            expandOnClick: false,
                            donut: {
                              size: "70px",
                              labels: {
                                show: true,
                                name: {},
                                value: {
                                  color: "#000",
                                  fontWeight: 700,
                                  fontFamily: "Poppins",
                                  fontSize: "24px",
                                },
                                total: {
                                  show: true,
                                  label: "Total",
                                  color: "#666",
                                  fontSize: "13px",
                                  fontFamily: "Poppins",
                                  formatter: function (w: any) {
                                    return w.globals.seriesTotals.reduce(
                                      (a: any, b: any) => {
                                        return a + b;
                                      },
                                      0
                                    );
                                  },
                                },
                              },
                            },
                          },
                        },
                        tooltip: {
                          followCursor: true,
                          fillSeriesColor: false,
                          theme: "light",
                        },
                      }}
                      series={counts.length > 0 ? counts : []}
                      type="donut"
                      width="373px"
                    />
                  )}
                </Box>
              </CardContent>
            ) : (
              <NoData height={300} />
            )}
          </>
        ) : (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: 280,
            }}
          >
            <CircularProgress />
          </Grid>
        )}
      </>
    </Card>
  );
};

export default OfferChart;
