import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "../../../../widgets/uncontrolled";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useForm } from "../../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FormattedMessage, useIntl } from "react-intl";
import Flags from "../../../Flags";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  section: string;
  save: Function;
  flags?: boolean;
}

export type FormStateValues = {
  title: { [key: string]: string } | null;
  short_code: string;
  flag: string;
  active: number;
};

const initValues = {
  title: null,
  short_code: "",
  flag: "",
  active: 1,
} as FormStateValues;

const SettingDialogue1: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );

  const handleClose = () => {
    props.setDialogue(false);
  };

  const onFlagChange = (fileName: string) => {
    form.values.flag = fileName;
  };

  const save = () => {
    props.save(form.validate(), () => {
      form.clear();
    });
  };

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"STANDARD.NEW"}
                  defaultMessage={"STANDARD.NEW"}
                />
              </Typography>
              <Button
                form={"setting_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_form"}
              onSubmit={(e) => form.handleSubmit(e, save)}
            >
              <Grid container spacing={2} mb={2}>
                {system_languages &&
                  system_languages.map((system_language) => {
                    return (
                      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                        {loaded ? (
                          <Controls.Input
                            name={`title_${system_language.short_code}`}
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            onChange={(event) => {
                              if (!event.target.value) return;

                              if (!form.values.title) {
                                form.values.title = {};
                              }

                              form.values.title[system_language.short_code] =
                                event.target.value;
                              event.preventDefault();
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {system_language.short_code.toUpperCase()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Input
                              name={`title_${system_language.short_code}`}
                              label={intl.formatMessage({
                                id: "STANDARD.TITLE",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                    );
                  })}
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Select
                      name={"active"}
                      formId={"status"}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({ id: "STANDARD.ACTIVE" }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"status"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                {props.flags && (
                  <>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded ? (
                        <Controls.Input
                          name={"short_code"}
                          label={intl.formatMessage({
                            id: "STANDARD.SHORT_CODE",
                            defaultMessage: "Short code",
                          })}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.short_code = event.target.value;
                            event.preventDefault();
                          }}
                        />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"title_da"}
                            label={"Title"}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded ? (
                        <Flags onChange={onFlagChange} />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            name={"status"}
                            options={[]}
                            label={intl.formatMessage({
                              id: "STANDARD.STATUS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SettingDialogue1;
