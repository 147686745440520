import React, { FC } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { getFileExtIcon } from "app/utils/standart";
import { toAbsoluteUrl } from "app/../_metronic";
import { IFile } from "../../../../services/file.service";

const Files: FC<{
  items: Array<IFile>;
  onClick?: (id: string) => void;
}> = (props) => {
  const { items, onClick } = props;
  return (
    <List sx={{ width: "100%", p: 0 }} dense={true}>
      {items.map((item) => {
        return (
          <ListItem
            href={
              process.env.REACT_APP_FILES_BASE_URL +
              `/storage/${item._id}/${item.name}`
            }
            component={"a"}
            target={"_blank"}
            sx={{ p: 0, marginTop: 2, cursor: "pointer" }}
            disableGutters
          >
            <ListItemAvatar
              sx={{
                minWidth: "44px",
              }}
            >
              <Box>
                <img
                  src={toAbsoluteUrl(
                    `/media/extension/${getFileExtIcon(item.name)}`
                  )}
                  alt={item.name}
                />
              </Box>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              sx={{
                m: 0,
                color: "black",
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default Files;
