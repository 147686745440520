import {
  IRestApiCollection,
  IRestApiError,
  IRestApiResource,
  IUrlQuery,
  IUser,
} from "../interfaces";
import { http } from "../lib/http";
import { API_ROUTES, getRoute } from "../config/apiRoutes";
import { generateFormData, queryToString } from "../utils/http";
import { ISetting } from "./setting.service";
import { ICase } from "./case.service";

export interface ITimeTrack {
  _id: string;
  from?: any;
  start_date_time: any;
  end_date_time: any;
  start_location: string;
  session_type: string;
  end_location: string;
  platform: string;
  status: number;
  duration_breaks: number;
  timeline: Array<{
    type: string;
    label: string;
    unixtime: number;
    location: string;
  }>;
  breaks: Array<{
    start: { data: number; location: string };
    end: { date: number; location: string };
  }>;
  case: ICase;
  type: ISetting;
  created_by: IUser;
  employee?: string;
  citizen?: string;
  _created_at: string;
  updated_at: number;
}

export interface ITimezone {
  id: number;
  slug: string;
  title: string;
}

interface ITimeTrackStoreDataSignature {
  [key: string]: any;
}

export interface ITimeTrackStore extends ITimeTrackStoreDataSignature {
  type: string;
  start_date_time: string;
  end_date_time: string;
  start_location: string;
  end_location: string;
}

export interface ITimeTrackStoreON {
  latitude: number;
  longitude: number;
  start_date_time: string;
  related: string;
  relation_type: string;
}

export interface ITimeTrackStoreOFF {
  latitude: number;
  longitude: number;
  end_date_time: string;
  type: string;
  platform: string;
  relation_type: string;
  // 'comment': string,
  // 'emojiIndex': number
}

const INDEX = API_ROUTES.time_tracks;
const SHOW = API_ROUTES.time_track;
const UPDATE = API_ROUTES.time_track;
const STORE = API_ROUTES.time_tracks;
const DELETE = API_ROUTES.time_track_delete;

const TIMEZONES = API_ROUTES.timezones;

const TRACK_ON = API_ROUTES.time_track_on;
const TRACK_OFF = API_ROUTES.time_track_off;
const TRACK_PAUSE = API_ROUTES.time_track_pause;
const TRACK_PLAY = API_ROUTES.time_track_play;

export interface ITimeTrackService {
  getAll(
    queries?: Array<IUrlQuery>
  ): Promise<IRestApiCollection<ITimeTrack> | IRestApiError>;
  getOne(_id: string): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;
  update(
    _id: string,
    data: ITimeTrackStore
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;
  delete(_id: string): Promise<IRestApiCollection<ITimeTrack> | IRestApiError>;
  add(
    data: ITimeTrackStore
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;

  on(
    data: ITimeTrackStoreON
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;
  off(
    _id: string,
    data: ITimeTrackStoreOFF
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;
  pause(
    _id: string,
    data: { location: { lat: number; lng: number } }
  ): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;
  play(_id: string): Promise<IRestApiResource<ITimeTrack> | IRestApiError>;
  timezones(): Promise<ITimezone[] | IRestApiError>;
}

const timeTrackService: ITimeTrackService = {
  async getAll(queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(`${getRoute(INDEX)}${queriesString}`, "get");
  },

  async getOne(_id: string) {
    return http(`${getRoute(SHOW).replace(":_id", _id.toString())}`, "get");
  },

  async update(_id: string, data: ITimeTrackStore) {
    let jsonData = JSON.stringify(data);
    return http(
      `${getRoute(UPDATE).replace(":_id", _id.toString())}`,
      "put",
      jsonData
    );
  },

  async delete(_id: string, queries?: Array<IUrlQuery>) {
    let queriesString = queries ? `?${queryToString(queries)}` : "";

    return http(
      `${getRoute(DELETE.replace(":_id", _id.toString()))}${queriesString}`,
      "delete"
    );
  },

  async add(data: ITimeTrackStore) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(STORE), "post", formData);
  },

  async on(data: ITimeTrackStoreON) {
    let formData = generateFormData(new FormData(), data);
    return http(getRoute(TRACK_ON), "post", formData);
  },

  async off(_id: string, data: ITimeTrackStoreOFF) {
    let formData = generateFormData(new FormData(), data);
    return http(
      getRoute(TRACK_OFF).replace(":_id", _id.toString()),
      "post",
      formData
    );
  },

  async pause(_id: string, data: { location: { lat: number; lng: number } }) {
    let formData = generateFormData(new FormData(), data);

    return http(
      getRoute(TRACK_PAUSE).replace(":_id", _id.toString()),
      "post",
      formData
    );
  },

  async play(_id: string) {
    let formData = generateFormData(new FormData(), []);

    return http(
      getRoute(TRACK_PLAY).replace(":_id", _id.toString()),
      "post",
      formData
    );
  },

  async timezones() {
    return http(`${getRoute(TIMEZONES)}`, "get");
  },
};

export { timeTrackService };
