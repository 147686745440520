import { FormattedMessage, useIntl } from "react-intl";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { timeTrackService } from "../services";
import Snackbar from "./Snackbar";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import moment from "moment/moment";
import ListItem from "@mui/material/ListItem";
import { ITimeTrack } from "../services/timeTrack.service";

interface TabPanelProps {
  children: any;
  value: any;
  index: any;
  other?: any;
}

const TabPanel = (props: TabPanelProps) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      sx={{ width: "100%" }}
      hidden={props.value !== props.index}
      id={`scrollable-auto-tabpanel-${props.index}`}
      aria-labelledby={`scrollable-auto-tab-${props.index}`}
      {...props.other}
    >
      <Box p={"1 1"}>{props.children}</Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const RenderRow = (props: ListChildComponentProps<Array<ITimeTrack>>) => {
  const { index, style, data } = props;
  const intl = useIntl();

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <Box className="kt-timeline-v2__item">
        <Box className="kt-timeline-v2__item-cricle">
          <Badge color="secondary" variant="dot" />
        </Box>
        <Box className="kt-timeline-v2__item-text kt-padding-top-5">
          {data[index].from == "case" ? (
            <Typography variant={"body1"}>
              <b style={{ color: "#556EE6" }}>{data[index].employee ?? ""}</b>{" "}
              <FormattedMessage
                id="TimeTrackWorkActivity"
                defaultMessage={"was at work with"}
              />{" "}
              <b style={{ color: "#556EE6" }}>{data[index].citizen ?? ""}</b>
            </Typography>
          ) : (
            <Typography variant={"body1"}>
              <b style={{ color: "#556EE6" }}>
                {data[index].created_by.firstname +
                  " " +
                  data[index].created_by.lastname ?? ""}
              </b>{" "}
              <FormattedMessage
                id="TimeTrackWorkUserActivity"
                defaultMessage={"was start time record"}
              />{" "}
            </Typography>
          )}
          <Typography>
            {moment
              .unix(data[index].start_date_time)
              .utc()
              .format("DD-MM-YYYY HH:mm")}{" "}
            /{" "}
            {data[index].end_date_time
              ? moment
                  .unix(data[index].end_date_time)
                  .utc()
                  .format("DD-MM-YYYY HH:mm")
              : intl.formatMessage({
                  id: "NO_DATA",
                  defaultMessage: "No data",
                })}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

const TimeRecordActivities = () => {
  const intl = useIntl();
  const [value, setValue] = useState<number>(0);
  const [timetracks, setTimetracks] = useState<ITimeTrack[]>();
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    let type = [
      {
        name: "from_unix",
        value: moment(moment().format("YYYY-MM-DD") + " 00:00:00").unix(),
      },
      {
        name: "to_unix",
        value: moment(moment().format("YYYY-MM-DD") + " 23:59:59").unix(),
      },
    ];
    if (tab === 2) {
      type = [
        {
          name: "from_unix",
          value: moment(moment().clone().startOf("isoWeek")).unix(),
        },
        {
          name: "to_unix",
          value: moment(moment().clone().endOf("isoWeek")).unix(),
        },
      ];
    } else if (tab === 3) {
      type = [
        {
          name: "from_unix",
          value: moment(moment().clone().startOf("month")).unix(),
        },
        {
          name: "to_unix",
          value: moment(moment().clone().endOf("month")).unix(),
        },
      ];
    }

    timeTrackService
      .getAll(type)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }
        const { data: timetracks } = data;

        setTimetracks(timetracks);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [tab]);

  function handleChange(event: any, newValue: number) {
    setValue(newValue);
  }

  return (
    <Card sx={{ padding: "0 20px", height: "349px" }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 16,
        }}
        sx={{ padding: "12px 0 12px 0" }}
        title={intl.formatMessage({ id: "TIMERECORD_ACTIVITY" })}
      />
      <Tabs value={value} onChange={handleChange} aria-label="Timetracks">
        <Tab
          sx={{
            pb: 0,
            pt: 0,
            pl: 2,
            pr: 2,
            minWidth: "66px",
            fontSize: 13,
            textTransform: "capitalize",
          }}
          label={intl.formatMessage({
            id: "STANDARD.TODAY",
            defaultMessage: "Today",
          })}
          onClick={() => {
            setTab(1);
          }}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            pb: 0,
            pt: 0,
            pl: 2,
            pr: 2,
            minWidth: "66px",
            fontSize: 13,
            textTransform: "capitalize",
          }}
          onClick={() => {
            setTab(2);
          }}
          label={intl.formatMessage({
            id: "STANDARD.THISWEEK",
            defaultMessage: "This week",
          })}
          {...a11yProps(1)}
        />
        <Tab
          sx={{
            pb: 0,
            pt: 0,
            pl: 2,
            pr: 2,
            minWidth: "66px",
            fontSize: 13,
            textTransform: "capitalize",
          }}
          onClick={() => {
            setTab(3);
          }}
          label={intl.formatMessage({
            id: "STANDARD.THISMONTH",
            defaultMessage: "This month",
          })}
          {...a11yProps(2)}
        />
      </Tabs>
      <CardContent sx={{ padding: 0 }}>
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabPanel value={value} index={0}>
              <Box
                className=" kt-timeline-v2-forcase"
                sx={{
                  "&::before": {
                    content: "unset",
                  },
                }}
              >
                <Box className="kt-timeline-v2__items-forcase">
                  <FixedSizeList
                    height={260}
                    width={"100%"}
                    itemSize={53}
                    itemCount={timetracks ? timetracks.length : 0}
                    itemData={timetracks ?? []}
                    overscanCount={5}
                  >
                    {/*{renderRow}*/}
                    {RenderRow}
                  </FixedSizeList>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                className=" kt-timeline-v2-forcase"
                sx={{
                  "&::before": {
                    content: "unset",
                  },
                }}
              >
                <Box className="kt-timeline-v2__items-forcase">
                  <FixedSizeList
                    height={260}
                    width={"100%"}
                    itemSize={53}
                    itemCount={timetracks ? timetracks.length : 0}
                    itemData={timetracks ?? []}
                    overscanCount={5}
                  >
                    {RenderRow}
                  </FixedSizeList>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box
                className=" kt-timeline-v2-forcase"
                sx={{
                  "&::before": {
                    content: "unset",
                  },
                }}
              >
                <Box className="kt-timeline-v2__items-forcase">
                  <FixedSizeList
                    height={260}
                    width={"100%"}
                    itemSize={53}
                    itemCount={timetracks ? timetracks.length : 0}
                    itemData={timetracks ?? []}
                    overscanCount={5}
                  >
                    {RenderRow}
                  </FixedSizeList>
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </>
      </CardContent>
    </Card>
  );
};

export default TimeRecordActivities;
