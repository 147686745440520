import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Snackbar from "../../../widgets/Snackbar";
import { permissionService } from "../../../services";
import { TEMPLATE, PermissionStructureType } from "app/permission/permissions";
import { checkKey } from "../../../utils/standart";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Skeleton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../../widgets/uncontrolled";
import { FormattedMessage, useIntl } from "react-intl";
import { TransitionProps } from "@mui/material/transitions";
import {
  Abilities,
  PermissionsFilterType,
  PermissionsType,
} from "../../../permission/PermissionsGate";
import * as setting from "../../../store/ducks/setting.duck";

interface IFormProps {
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: string;
  structure: PermissionStructureType | null;
};

interface IPermissionRow {
  rowTitle: PermissionsType;
  title: string;
  abilities: Array<Abilities>;
}

const initValues = {
  title: "test",
  structure: {
    settings: {
      title: "PERMISSION.TITLE.SETTINGS",
      abilities: [],
    },
    vacancies: {
      title: "PERMISSION.TITLE.VACANCIES",
      abilities: [],
    },
    mailboxes: {
      title: "PERMISSION.TITLE.MAILBOX",
      abilities: [],
    },
    surveys: {
      title: "PERMISSION.TITLE.SURVEY",
      abilities: [],
    },
    logs: {
      title: "PERMISSION.TITLE.LOGS",
      abilities: [],
    },
    reports: {
      title: "PERMISSION.TITLE.REPORTS",
      abilities: [],
    },
    exports: {
      title: "PERMISSION.TITLE.EXPORTS",
      abilities: [],
    },
    statistics: {
      title: "PERMISSION.TITLE.STATISTICS",
      abilities: [],
    },
    cases: {
      title: "PERMISSION.TITLE.CASES",
      abilities: [],
    },
    contracts: {
      title: "PERMISSION.TITLE.CONTRACTS",
      abilities: [],
    },
    accounting: {
      title: "PERMISSION.TITLE.CONTRACTS",
      abilities: [],
    },
    case_tradingplans: {
      title: "PERMISSION.TITLE.CASES.TRADINGPLANS",
      abilities: [],
    },
    case_evalreports: {
      title: "PERMISSION.TITLE.CASES.EVALREPORTS",
      abilities: [],
    },
    activity_payments: {
      title: "PERMISSION.TITLE.ACTIVITY.PAYMENTS",
      abilities: [],
    },
    case_timerecords: {
      title: "PERMISSION.TITLE.CASES.TIMERECORDS",
      abilities: [],
    },
    users: {
      title: "PERMISSION.TITLE.USER",
      abilities: [],
    },
    chat: {
      title: "PERMISSION.TITLE.CHAT",
      abilities: [],
    },
    message: {
      title: "PERMISSION.TITLE.MESSAGE",
      abilities: [],
    },
    todos: {
      title: "PERMISSION.TITLE.TODO",
      abilities: [],
    },
    notes: {
      title: "PERMISSION.TITLE.NOTES",
      abilities: [],
    },
    documents: {
      title: "PERMISSION.TITLE.DOCUMENTS",
      abilities: [],
    },
    guidelines: {
      title: "PERMISSION.TITLE.GUIDELINES",
      abilities: [],
    },
    values: {
      title: "PERMISSION.TITLE.VALUES",
      abilities: [],
    },
    knowledgebase: {
      title: "PERMISSION.TITLE.KNOWLEDGEBASE",
      abilities: [],
    },
    communes: {
      title: "PERMISSION.TITLE.COMMUNES",
      abilities: [],
    },
    contract_templates: {
      title: "PERMISSION.TITLE.CONTRACT_TEMPLATE",
      abilities: [],
    },
    incoming_offers: {
      title: "PERMISSION.TITLE.INCOMINGOFFERS",
      abilities: [],
    },
    offers: {
      title: "PERMISSION.TITLE.OFFERS",
      abilities: [],
    },
    time_records: {
      title: "PERMISSION.TITLE.TIMERECORDS",
      abilities: [],
    },
    time_record_switch: {
      title: "PERMISSION.TITLE.TIMERECORD.SWITCH",
      abilities: [],
    },
    time_track_user: {
      title: "PERMISSION.TITLE.TIME_TRACK_USER",
      abilities: [],
    },
    user_payment: {
      title: "PERMISSION.TITLE.USER_PAYMENT",
      abilities: [],
    },
    projects: {
      title: "PERMISSION.TITLE.PROJECTS",
      abilities: [],
    },
    tasks: {
      title: "PERMISSION.TITLE.TASKS",
      abilities: [],
    },
    calls: {
      title: "PERMISSION.TITLE.CALLS",
      abilities: [],
    },
  },
} as FormStateValues;

const PermissionStore: FC<IFormProps> = (props) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>(initValues);
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [template, setTemplate] = useState<Array<IPermissionRow> | null>(null);
  const dispatch = useDispatch();
  const addSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Add, payload: s }),
    [dispatch]
  );

  useEffect(() => {
    const rows: Array<IPermissionRow> = [];

    for (let key in TEMPLATE) {
      if (!checkKey<PermissionStructureType>(key, TEMPLATE)) {
        continue;
      }

      const _v = TEMPLATE[key];
      rows.push({
        rowTitle: key,
        title: _v.title,
        abilities: _v.abilities,
      });
    }

    setTemplate(rows);
  }, []);

  useEffect(() => {
    if (props.open) {
      form.values = initValues;
    }
  }, [props.open]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    permissionService
      .add(validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.ADDED" }));

        let { data: setting } = data;
        addSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  const renderContent = (
    data: PermissionsType
  ): Array<PermissionsFilterType> => {
    switch (data) {
      case "cases":
        return [
          "viewAny_attached_case",
          "update_attached_case",
          "delete_attached_case",
        ];
      case "contracts":
        return [
          "viewAny_contract_auto_todo",
          "update_contract_auto_todo",
          "delete_contract_auto_todo",
          "viewAny_contract_note",
          "update_contract_note",
          "delete_contract_note",
        ];
      case "chat":
        return [
          "viewAny_attached_chat",
          "update_attached_chat",
          "delete_attached_chat",
        ];
      case "todos":
        return [
          "viewAny_attached_todo",
          "update_attached_todo",
          "delete_attached_todo",
          "viewAny_todo_note",
          "update_todo_note",
          "delete_todo_note",
        ];
      case "notes":
        return [
          "viewAny_attached_note",
          "update_attached_note",
          "delete_attached_note",
        ];
      case "case_tradingplans":
        return [
          "viewAny_case_trading_plan_note",
          "update_case_trading_plan_note",
          "delete_case_trading_plan_note",
        ];
      case "activity_payments":
        return [
          "viewAny_activity_payment_note",
          "update_activity_payment_note",
          "delete_activity_payment_note",
        ];
      case "vacancies":
        return [
          "viewAny_vacancy_note",
          "update_vacancy_note",
          "delete_vacancy_note",
        ];
      default:
        return [];
    }
  };

  const renderKey = (data: string) => {
    switch (data) {
      case "viewAny_vacancy_note":
      case "update_vacancy_note":
      case "delete_vacancy_note":
        return "vacancies";

      case "viewAny_activity_payment_note":
      case "update_activity_payment_note":
      case "delete_activity_payment_note":
        return "activity_payments";

      case "viewAny_case_trading_plan_note":
      case "update_case_trading_plan_note":
      case "delete_case_trading_plan_note":
        return "case_tradingplans";

      case "viewAny_attached_note":
      case "update_attached_note":
      case "delete_attached_note":
        return "notes";

      case "viewAny_attached_todo":
      case "update_attached_todo":
      case "delete_attached_todo":
      case "viewAny_todo_note":
      case "update_todo_note":
      case "delete_todo_note":
        return "todos";

      case "remove_user":
      case "leave":
      case "viewAny_attached_chat":
      case "update_attached_chat":
      case "delete_attached_chat":
        return "chat";

      case "sign":
      case "viewAny_contract_auto_todo":
      case "update_contract_auto_todo":
      case "delete_contract_auto_todo":
      case "viewAny_contract_note":
      case "update_contract_note":
      case "delete_contract_note":
        return "contracts";

      case "attach_user":
      case "viewAny_attached_case":
      case "update_attached_case":
      case "delete_attached_case":
        return "cases";
    }
  };

  return (
    <>
      {
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={props.open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"STANDARD.NEW"}
                  defaultMessage={"STANDARD.NEW"}
                />
              </Typography>
              <Button
                form={"permission_store"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"permission_store"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <Controls.Input
                      name={"title"}
                      label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.title = event.target.value;

                        event.preventDefault();
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"title"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded ? (
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="sticky collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"create"}
                                defaultMessage={"Create"}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"read"}
                                defaultMessage={"Read"}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"update"}
                                defaultMessage={"Update"}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <FormattedMessage
                                id={"delete"}
                                defaultMessage={"Delete"}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {template?.map((row, key) => {
                            return (
                              <>
                                <TableRow key={key}>
                                  <TableCell component="th" scope="row">
                                    <FormattedMessage
                                      id={row.title}
                                      defaultMessage={row.title}
                                    />
                                  </TableCell>
                                  {["create", "read", "update", "delete"].map(
                                    (value, index) => {
                                      return (
                                        <TableCell align={"center"}>
                                          {row.abilities[index] ? (
                                            <Checkbox
                                              id={`${row.rowTitle}_${row.abilities[index]}`}
                                              onChange={(event) => {
                                                let { structure } = form.values;

                                                if (
                                                  !structure ||
                                                  !structure[row.rowTitle]
                                                )
                                                  return;

                                                if (event.target.checked) {
                                                  structure[
                                                    row.rowTitle
                                                  ].abilities.push(
                                                    value as Abilities
                                                  );
                                                } else {
                                                  structure[
                                                    row.rowTitle
                                                  ].abilities = structure[
                                                    row.rowTitle
                                                  ].abilities.filter(
                                                    (ability) =>
                                                      ability !== value
                                                  );
                                                }
                                              }}
                                            />
                                          ) : null}
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"titles"}
                        label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default PermissionStore;
