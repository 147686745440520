import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { permissionService, roleService } from "../../../services";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Skeleton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IPermission, IRole } from "../../../interfaces";
import { FormattedMessage, useIntl } from "react-intl";
import { Palette } from "../../../widgets/Palette";
import * as setting from "../../../store/ducks/setting.duck";

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: { [key: string]: string } | null;
  permissions: string;
  access_level: number;
  color: string;
};

const ShowRole = (props: FormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const [mode, setMode] = useState<string>("show");
  const [role, setRole] = useState<IRole>();
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [permissions, setPermissions] = useState<Array<IPermission>>();
  const colorRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  useEffect(() => {
    permissionService
      .getAll()
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        const { data: permissions } = data;
        setPermissions(permissions);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, []);

  useEffect(() => {
    roleService
      .getOne(props._id)
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: role } = data;
        setRole(role);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    roleService
      .update(props._id, validated)
      .then((data) => {
        if ("error" in data) {
          throw Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        let { data: setting } = data;
        updateSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (role) {
      form.values.title = role.title;
      form.values.permissions = role.permissions._id;
      form.values.color = role.color;
      form.values.access_level = role.access_level;
    }
  }, [role]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"role_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"role_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                {system_languages &&
                  system_languages.map((system_language) => {
                    return (
                      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                        {loaded && role ? (
                          <Controls.Input
                            name={`title_${system_language.short_code}`}
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            defaultValue={
                              role?.title[system_language.short_code]
                            }
                            onChange={(event) => {
                              if (!event.target.value) return;

                              if (!form.values.title) {
                                form.values.title = {};
                              }

                              form.values.title[system_language.short_code] =
                                event.target.value;

                              event.preventDefault();
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {system_language.short_code.toUpperCase()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Input
                              name={`title_${system_language.short_code}`}
                              label={intl.formatMessage({
                                id: "STANDARD.TITLE",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                    );
                  })}
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded && role ? (
                    <>
                      <Controls.Input
                        name={"color"}
                        label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                        inputRef={colorRef}
                        defaultValue={role?.color}
                        onChange={(event) => {
                          if (!event.target.value) return;

                          form.values.color = event.target.value;

                          event.preventDefault();
                        }}
                      />
                      <Grid container>
                        <Palette
                          onPick={(color) => {
                            if (color) {
                              form.values.color = color;
                              if (colorRef.current)
                                colorRef.current.value = color;
                            } else {
                              form.values.color = "";
                              if (colorRef.current) colorRef.current.value = "";
                            }
                          }}
                          defaultValue={role?.color}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"color"}
                        label={intl.formatMessage({ id: "STANDARD.COLOR" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {loaded && role ? (
                    <Controls.Select
                      name={"permission"}
                      formId={"permission"}
                      options={permissions?.map((permission) => {
                        return {
                          id: permission._id,
                          title: permission.title,
                        };
                      })}
                      defaultValue={role?.permissions?._id}
                      label={intl.formatMessage({
                        id: "SETTINGS.PERMISSION",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.permissions = event.target.value.toString();
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"permission"}
                        label={intl.formatMessage({
                          id: "SETTINGS.PERMISSION",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && role ? (
                    <Controls.Select
                      name={"access_level"}
                      formId={"access_level"}
                      options={[
                        {
                          id: "0",
                          title: "0 - user, only own data",
                        },
                        {
                          id: "1",
                          title:
                            "1 - moderator, data of other users. can`t change permissions",
                        },
                        {
                          id: "2",
                          title:
                            "2 - moderator, data of other users. can change permission of lower",
                        },
                        {
                          id: "3",
                          title:
                            "3 - super-admin, data of users, can change admins permission. multi-business",
                        },
                      ]}
                      label={intl.formatMessage({
                        id: "SETTINGS.ACCESSLEVEL",
                      })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.access_level = event.target.value;
                      }}
                      defaultValue={role?.access_level && +role.access_level}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Input
                        name={"access_level"}
                        label={intl.formatMessage({
                          id: "SETTINGS.ACCESSLEVEL",
                        })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowRole;
