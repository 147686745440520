import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Snackbar from "../../../widgets/Snackbar";
import { settingService } from "../../../services";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Controls from "../../../widgets/uncontrolled";
import { TransitionProps } from "@mui/material/transitions";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ISetting } from "../../../services/setting.service";
import { FormattedMessage, useIntl } from "react-intl";
import * as setting from "app/store/ducks/setting.duck";

interface _ISettings extends ISetting {
  amount?: string;
  amount_type?: string;
}

interface FormProps {
  _id: string;
  open: boolean;
  setDialogue: Dispatch<SetStateAction<boolean>>;
  onDone: Function;
}

type FormStateValues = {
  title: { [key: string]: string } | null;
  active: number;
  amount: string;
  amount_type: string;
};

const ShowPosition = (props: FormProps) => {
  const intl = useIntl();
  const form = useForm<FormStateValues>();
  const loaded = useSelector(
    (state: RootState) => state.settingReducer && state.permission
  );
  const { system_languages } = useSelector(
    (state: RootState) => state.settingReducer
  );
  const [position, setFocusType] = useState<_ISettings>();
  const { user: me } = useSelector(({ auth }: RootState) => auth);
  const dispatch = useDispatch();
  const updateSetting = useCallback(
    (s) => dispatch({ type: setting.actionTypes.Update, payload: s }),
    [dispatch]
  );

  useEffect(() => {
    settingService
      .getOne(props._id, [
        {
          name: "type",
          value: "positions",
        },
      ])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        let { data: position } = data;
        setFocusType(position);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  }, [props._id]);

  const handleClose = () => {
    props.setDialogue(false);
  };

  const handleSave = () => {
    let validated = form.validate();

    if (!validated) {
      Snackbar.error(intl.formatMessage({ id: "SNACKBAR.STOREERROR" }));
      return;
    }

    settingService
      .update(props._id, validated, [{ name: "type", value: "positions" }])
      .then((data) => {
        if ("error" in data) {
          throw new Error(data.error.message);
        }

        Snackbar.success(intl.formatMessage({ id: "SNACKBAR.UPDATED" }));

        let { data: setting } = data;
        updateSetting(setting);

        setTimeout(() => {
          props.setDialogue(false);
          props.onDone();
          form.clear();
        }, 1000);
      })
      .catch((e) => {
        Snackbar.error(e.message);
      });
  };

  useEffect(() => {
    if (position) {
      form.values.title = position.title;
      form.values.active = position.active;
    }
  }, [position]);

  return (
    <>
      {
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "rgb(245, 245, 245)",
              boxShadow: "none",
              p: "0px 20px",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ flex: 1, color: "#000000" }}
                variant="h6"
                component="div"
              >
                <FormattedMessage
                  id={"DIALOGUE.EDIT"}
                  defaultMessage={"DIALOGUE.EDIT"}
                />
              </Typography>
              <Button
                form={"setting_position_form"}
                autoFocus
                type={"submit"}
                sx={{
                  backgroundColor: "#0D99FF",
                  borderRadius: 2,
                  color: "#ffffff",
                }}
              >
                <FormattedMessage
                  id={"DIALOGUE.SAVE"}
                  defaultMessage={"DIALOGUE.SAVE"}
                />
              </Button>
            </Toolbar>
          </Box>
          <DialogContent>
            <form
              id={"setting_position_form"}
              onSubmit={(e) => form.handleSubmit(e, handleSave)}
            >
              <Grid container spacing={2} mb={2}>
                {system_languages &&
                  system_languages.map((system_language) => {
                    return (
                      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                        {loaded && position ? (
                          <Controls.Input
                            name={`title_${system_language.short_code}`}
                            label={intl.formatMessage({ id: "STANDARD.TITLE" })}
                            defaultValue={
                              position?.title[system_language.short_code]
                            }
                            onChange={(event) => {
                              if (!event.target.value) return;

                              if (!form.values.title) {
                                form.values.title = {};
                              }

                              form.values.title[system_language.short_code] =
                                event.target.value;

                              event.preventDefault();
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {system_language.short_code.toUpperCase()}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Skeleton width={"100%"}>
                            <Controls.Input
                              name={`title_${system_language.short_code}`}
                              label={intl.formatMessage({
                                id: "STANDARD.TITLE",
                              })}
                              onChange={() => {}}
                            />
                          </Skeleton>
                        )}
                      </Grid>
                    );
                  })}
                {me?.role.slug === "super-admin" && (
                  <>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded && position ? (
                        <Controls.Input
                          name={"amount"}
                          label={intl.formatMessage({ id: "STANDARD.AMOUNT" })}
                          defaultValue={position.amount}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.amount = event.target.value;
                          }}
                        />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Input
                            name={"amount"}
                            label={intl.formatMessage({
                              id: "STANDARD.AMOUNT",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                      {loaded && position ? (
                        <Controls.Select
                          name={"amount_type"}
                          formId={"amount_type"}
                          defaultValue={position.amount_type}
                          options={[
                            {
                              id: "1_hour",
                              title: intl.formatMessage({
                                id: "STANDARD.ONE_HOUR",
                              }),
                            },
                            {
                              id: "1_month",
                              title: intl.formatMessage({
                                id: "STANDARD.ONE_MONTH",
                              }),
                            },
                          ]}
                          label={intl.formatMessage({
                            id: "STANDARD.AMOUNT_TYPE",
                          })}
                          onChange={(event) => {
                            if (!event.target.value) return;

                            form.values.amount_type = event.target.value;
                          }}
                        />
                      ) : (
                        <Skeleton width={"100%"}>
                          <Controls.Select
                            name={"active"}
                            options={[]}
                            label={intl.formatMessage({
                              id: "STANDARD.STATUS",
                            })}
                            onChange={() => {}}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                  {loaded && position ? (
                    <Controls.Select
                      name={"active"}
                      formId={"status"}
                      defaultValue={position?.active}
                      options={[
                        {
                          id: "1",
                          title: intl.formatMessage({
                            id: "STANDARD.ACTIVE",
                          }),
                        },
                        {
                          id: "0",
                          title: intl.formatMessage({
                            id: "STANDARD.DEACTIVE",
                          }),
                        },
                      ]}
                      label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                      onChange={(event) => {
                        if (!event.target.value) return;

                        form.values.active = event.target.value;
                      }}
                    />
                  ) : (
                    <Skeleton width={"100%"}>
                      <Controls.Select
                        name={"active"}
                        options={[]}
                        label={intl.formatMessage({ id: "STANDARD.STATUS" })}
                        onChange={() => {}}
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ShowPosition;
