import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { caseService } from "../services";
import NoData from "../partials/NoData";

const options = ["all", "month", "week"];

const ITEM_HEIGHT = 48;

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; bgColor: string }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            height: "8px",
            borderRadius: 12,
            backgroundColor: "#E1E6FB",
            "& .MuiLinearProgress-bar1Determinate": {
              backgroundColor: props.bgColor,
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 13, fontWeight: 700 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const FeedbackChart = () => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>(
    intl.formatMessage({
      id: "STANDARD.ALL",
    })
  );
  const [data, setData] = useState<{ emoji: number; count: number }[]>([]);
  const [count, setCount] = useState<number>(0);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    caseService
      .getFeedbackStatistics([
        {
          name: "filter",
          value: filter,
        },
      ])
      .then((data) => {
        setData(data.data);
        setCount(data.count);
      });
  }, [filter]);

  const handleClose = (key: string) => {
    setFilter(key);
    setAnchorEl(null);
  };

  const getSvg = (emoji: number) => {
    switch (emoji) {
      case -2:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47778 20 0 15.5228 0 10C0 4.47778 4.47778 0 10 0C15.5228 0 20 4.47778 20 10Z"
              fill="#FFCC4D"
            />
            <path
              d="M14.1584 16.5995C14.1334 16.5001 13.5095 14.1667 10.0001 14.1667C6.4901 14.1667 5.86676 16.5001 5.84176 16.5995C5.81121 16.7201 5.86565 16.8451 5.97343 16.9073C6.08176 16.9678 6.21732 16.9512 6.30676 16.8645C6.31732 16.8539 7.39232 15.8334 10.0001 15.8334C12.6079 15.8334 13.6834 16.8539 13.6934 16.8639C13.7468 16.9167 13.8179 16.9445 13.889 16.9445C13.9357 16.9445 13.9829 16.9328 14.0257 16.9089C14.1345 16.8467 14.189 16.7206 14.1584 16.5995ZM8.72621 9.60728C6.91787 7.79894 4.01176 7.77783 3.88899 7.77783C3.58232 7.77783 3.33399 8.02617 3.33399 8.33228C3.33343 8.63894 3.58176 8.88783 3.88843 8.88894C3.90454 8.88894 4.95787 8.90117 6.10121 9.29839C5.77176 9.65394 5.55565 10.2062 5.55565 10.8334C5.55565 11.9078 6.17732 12.7778 6.94454 12.7778C7.71176 12.7778 8.33343 11.9078 8.33343 10.8334C8.33343 10.7367 8.32287 10.6445 8.31287 10.5517C8.3201 10.5517 8.32676 10.5556 8.33343 10.5556C8.47565 10.5556 8.61787 10.5012 8.72621 10.3928C8.94343 10.1756 8.94343 9.8245 8.72621 9.60728ZM16.1112 7.77783C15.9884 7.77783 13.0829 7.79894 11.274 9.60728C11.0568 9.8245 11.0568 10.1756 11.274 10.3928C11.3823 10.5012 11.5245 10.5556 11.6668 10.5556C11.674 10.5556 11.6801 10.5517 11.6868 10.5517C11.6779 10.6445 11.6668 10.7367 11.6668 10.8334C11.6668 11.9078 12.2884 12.7778 13.0557 12.7778C13.8229 12.7778 14.4445 11.9078 14.4445 10.8334C14.4445 10.2062 14.2284 9.65394 13.899 9.29839C15.0423 8.90117 16.0957 8.88894 16.1123 8.88894C16.4184 8.88783 16.6668 8.63894 16.6662 8.33228C16.6657 8.02617 16.4179 7.77783 16.1112 7.77783Z"
              fill="#664500"
            />
          </svg>
        );
      case -1:
        return (
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20.75C15.5228 20.75 20 16.2728 20 10.75C20 5.22715 15.5228 0.75 10 0.75C4.47715 0.75 0 5.22715 0 10.75C0 16.2728 4.47715 20.75 10 20.75Z"
              fill="#FFCC4D"
            />
            <path
              d="M14.1581 15.9606C14.1331 15.8612 13.5092 13.5278 9.99977 13.5278C6.48977 13.5278 5.86644 15.8612 5.84144 15.9606C5.81088 16.0812 5.86533 16.2062 5.97311 16.2684C6.08144 16.3301 6.217 16.3117 6.30644 16.2256C6.317 16.2151 7.392 15.1945 9.99977 15.1945C12.6076 15.1945 13.6831 16.2151 13.6931 16.2251C13.7464 16.2778 13.8176 16.3056 13.8887 16.3056C13.9353 16.3056 13.9826 16.2939 14.0253 16.2701C14.1342 16.2078 14.1887 16.0817 14.1581 15.9606Z"
              fill="#664500"
            />
            <path
              d="M6.66672 10.1946C7.43378 10.1946 8.05561 9.324 8.05561 8.25011C8.05561 7.17622 7.43378 6.30566 6.66672 6.30566C5.89966 6.30566 5.27783 7.17622 5.27783 8.25011C5.27783 9.324 5.89966 10.1946 6.66672 10.1946Z"
              fill="#664500"
            />
            <path
              d="M13.3332 10.1946C14.1003 10.1946 14.7221 9.324 14.7221 8.25011C14.7221 7.17622 14.1003 6.30566 13.3332 6.30566C12.5662 6.30566 11.9443 7.17622 11.9443 8.25011C11.9443 9.324 12.5662 10.1946 13.3332 10.1946Z"
              fill="#664500"
            />
          </svg>
        );
      case 0:
        return (
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47778 20.5 0 16.0228 0 10.5C0 4.97778 4.47778 0.5 10 0.5C15.5228 0.5 20 4.97778 20 10.5Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.38889 11.6111C7.15595 11.6111 7.77778 10.7405 7.77778 9.66661C7.77778 8.59273 7.15595 7.72217 6.38889 7.72217C5.62183 7.72217 5 8.59273 5 9.66661C5 10.7405 5.62183 11.6111 6.38889 11.6111Z"
              fill="#664500"
            />
            <path
              d="M13.6111 11.6111C14.3781 11.6111 14.9999 10.7405 14.9999 9.66661C14.9999 8.59273 14.3781 7.72217 13.6111 7.72217C12.844 7.72217 12.2222 8.59273 12.2222 9.66661C12.2222 10.7405 12.844 11.6111 13.6111 11.6111Z"
              fill="#664500"
            />
            <path
              d="M13.889 14.9446H6.11122C5.80455 14.9446 5.55566 14.6963 5.55566 14.3891C5.55566 14.0818 5.80455 13.8335 6.11122 13.8335H13.889C14.1962 13.8335 14.4446 14.0818 14.4446 14.3891C14.4446 14.6963 14.1962 14.9446 13.889 14.9446Z"
              fill="#664500"
            />
          </svg>
        );
      case 1:
        return (
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20.25C15.5228 20.25 20 15.7728 20 10.25C20 4.72715 15.5228 0.25 10 0.25C4.47715 0.25 0 4.72715 0 10.25C0 15.7728 4.47715 20.25 10 20.25Z"
              fill="#FFCC4D"
            />
            <path
              d="M5.84143 13.3728C5.86643 13.4723 6.49032 15.8056 9.99977 15.8056C13.5098 15.8056 14.1331 13.4723 14.1581 13.3728C14.1887 13.2523 14.1342 13.1273 14.0264 13.0651C13.9181 13.0034 13.7825 13.0217 13.6931 13.1078C13.6825 13.1184 12.6075 14.1389 9.99977 14.1389C7.39199 14.1389 6.31643 13.1184 6.30643 13.1084C6.2531 13.0556 6.18199 13.0278 6.11088 13.0278C6.06421 13.0278 6.01699 13.0395 5.97421 13.0634C5.86532 13.1256 5.81088 13.2517 5.84143 13.3728Z"
              fill="#664500"
            />
            <path
              d="M6.66672 9.69455C7.43378 9.69455 8.05561 8.824 8.05561 7.75011C8.05561 6.67622 7.43378 5.80566 6.66672 5.80566C5.89966 5.80566 5.27783 6.67622 5.27783 7.75011C5.27783 8.824 5.89966 9.69455 6.66672 9.69455Z"
              fill="#664500"
            />
            <path
              d="M13.3332 9.69455C14.1003 9.69455 14.7221 8.824 14.7221 7.75011C14.7221 6.67622 14.1003 5.80566 13.3332 5.80566C12.5662 5.80566 11.9443 6.67622 11.9443 7.75011C11.9443 8.824 12.5662 9.69455 13.3332 9.69455Z"
              fill="#664500"
            />
          </svg>
        );
      case 2:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47778 20 0 15.5228 0 10C0 4.47778 4.47778 0 10 0C15.5228 0 20 4.47778 20 10Z"
              fill="#FFCC4D"
            />
            <path
              d="M6.38889 9.99978C7.15595 9.99978 7.77778 8.63176 7.77778 6.94423C7.77778 5.25669 7.15595 3.88867 6.38889 3.88867C5.62183 3.88867 5 5.25669 5 6.94423C5 8.63176 5.62183 9.99978 6.38889 9.99978Z"
              fill="#664500"
            />
            <path
              d="M13.6111 9.99978C14.3781 9.99978 14.9999 8.63176 14.9999 6.94423C14.9999 5.25669 14.3781 3.88867 13.6111 3.88867C12.844 3.88867 12.2222 5.25669 12.2222 6.94423C12.2222 8.63176 12.844 9.99978 13.6111 9.99978Z"
              fill="#664500"
            />
            <path
              d="M9.99978 12.2224C7.98701 12.2224 6.65145 11.9879 4.99978 11.6668C4.62256 11.594 3.88867 11.6668 3.88867 12.7779C3.88867 15.0001 6.44145 17.7779 9.99978 17.7779C13.5576 17.7779 16.1109 15.0001 16.1109 12.7779C16.1109 11.6668 15.377 11.5935 14.9998 11.6668C13.3481 11.9879 12.0126 12.2224 9.99978 12.2224Z"
              fill="#664500"
            />
            <path
              d="M5 12.7778C5 12.7778 6.66667 13.3334 10 13.3334C13.3333 13.3334 15 12.7778 15 12.7778C15 12.7778 13.8889 15.0001 10 15.0001C6.11111 15.0001 5 12.7778 5 12.7778Z"
              fill="white"
            />
          </svg>
        );
      default:
        return <></>;
    }
  };

  const getTitle = (emoji: number) => {
    switch (emoji) {
      case -2:
        return "Very bad";
      case -1:
        return "Bad";
      case 0:
        return "Neutral";
      case 1:
        return "Good";
      case 2:
        return "Very good";
      default:
        return "";
    }
  };

  const getColor = (emoji: number) => {
    switch (emoji) {
      case -2:
        return "#EC3033";
      case -1:
        return "#FBB73C";
      case 0:
        return "#035189";
      case 1:
        return "#45C3ED";
      case 2:
        return "#3DCA81";
      default:
        return "";
    }
  };

  return (
    <Card sx={{ px: 2, py: 0, height: "316px" }}>
      <CardHeader
        titleTypographyProps={{
          fontSize: 16,
        }}
        sx={{ padding: "12px 0 10px 0" }}
        title={intl.formatMessage({ id: "CASE.FEEDBACKS" })}
        action={
          <>
            <IconButton
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              edge="start"
              color="inherit"
              disableFocusRipple={true}
              sx={{
                borderRadius: 0,
                padding: "8px 0 0 0",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-label="close"
            >
              <Typography
                variant={"inherit"}
                sx={{ fontSize: "13px", textTransform: "capitalize" }}
              >
                {filter}
              </Typography>
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(filter)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === filter}
                  sx={{
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleClose(option);
                  }}
                >
                  {intl.formatMessage({
                    id: "STANDARD." + option.toUpperCase(),
                  })}
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      />
      <CardContent sx={{ padding: 0 }}>
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
            {data && data.length ? (
              data.map((d) => {
                return (
                  <Box sx={{ mb: "15px", width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {getSvg(+d.emoji)}
                      <Typography
                        variant={"h5"}
                        sx={{
                          fontSize: 13,
                          fontWeight: 400,
                          color: "#2A3042",
                          marginLeft: "10px",
                        }}
                      >
                        {getTitle(+d.emoji)}
                      </Typography>
                    </Box>

                    <LinearProgressWithLabel
                      value={(d.count * 100) / count}
                      bgColor={getColor(d.emoji)}
                    />
                  </Box>
                );
              })
            ) : (
              <NoData height={280} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FeedbackChart;
